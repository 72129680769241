/** AppComponent: this component is bootstrap component*/
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { AppEnum } from './modules/shared/common/enums';
import { browserType, Util } from './modules/shared/common/util';
import { CommonService } from './modules/shared/services/common.service';
import { LocalStorageService } from './modules/shared/services/webstorage.service';

@Component({
    selector: 'ay-app',
    template: '<router-outlet></router-outlet>',
    styles: [' ngb-modal-backdrop {z-index: 1050 !important;} .modal-dialog{margin: 1.75rem auto !important;}'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    notAllowedBrowsers = [browserType.IE, browserType.OldChrome, browserType.Opera, browserType.Firefox];
    storageService!: LocalStorageService;
    /** constructor: Router is injected and used to navigate  */
    constructor(private router: Router, private commonService: CommonService) {}

    /** ngOnInit will be called after the first ngOnChanges */
    ngOnInit(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }

            if (evt instanceof NavigationEnd) {
                this.commonService.checkUserAccess(evt.url);
            }

            window.scrollTo(0, 0);
        });

        const userBrowser = Util.getBrowser();
        let userId = '';
        let authToken = '';
        let isContinue: string | null = AppEnum.trueFlaseStr.false;
        if (navigator.cookieEnabled) {
            isContinue = localStorage.getItem('isContinue');
            this.storageService = new LocalStorageService();
            userId = this.storageService.get('userId');
            authToken = this.storageService.get('oauth_token');
        }
        if (
            !navigator.cookieEnabled
            // || (isContinue !== AppEnum.trueFlaseStr.true && this.notAllowedBrowsers.some((browser) => browser === userBrowser))
        ) {
            this.router.navigateByUrl('/auth/browser-message');
        }
    }
}
