/** P404Component: this component will be used to display when there is 400 error*/
import { Component } from '@angular/core';

@Component({
    templateUrl: '404.component.html'
})
export class P404Component {
    /** constructor */
    constructor() {}
}
