/** P500Component: this component will be used to display when there is 500 error*/
import { Component } from '@angular/core';

@Component({
    templateUrl: '500.component.html'
})
export class P500Component {
    /** constructor */
    constructor() {}
}
