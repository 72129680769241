// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const applicationHost = window.location.protocol + '//' + window.location.hostname;
const webUrlStr = window.location.protocol + '//' + window.location.host;
let ApiUrl=applicationHost + ':8080/';
if(applicationHost !== 'http://localhost' && applicationHost !== 'http://192.168.3.66'){
    ApiUrl=applicationHost + '/agyield-services/';
}
export const environment = {
    applicationHost,
    production: false,
    authTokenKey: 'oauth_token',
    angWebUrl: webUrlStr,
    webUrl: webUrlStr,
    baseUrl: webUrlStr,
    urlWeb: webUrlStr,
    apiBaseUrl: ApiUrl,
    // sentryUrl: 'https://224a17523b1e4f24a2b55a27845d9a8d@sentry.io/1862446',
    basicPlanId: 10,
    proPlanId: 2,
    dateFormatForField: 'MM-dd-yyyy', // Use this format for HTML editable control
    dateFormatForInsurance: 'MM/dd/yyyy', // Use this format for HTML editable control
    ayDateFormat: 'MM/dd/yyyy', // Use this format for HTML editable control
    dateFormatExpFlexRentDateRange: 'yyyy-MM-dd' // Use this format for HTML editable control
};
