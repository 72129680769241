<ay-loader></ay-loader>

<c-header class="app-header navbar"
    [navbarBrandRouterLink]="[homeLink]"

    [navbarBrandFull]="{
        src: '/assets/img/brand/logo-small.png',
        width: 33,
        height: 30,
        alt: 'agyield'
    }"
    [navbarBrandMinimized]="{
        src: '/assets/img/brand/logo-small.png',
        width: 33,
        height: 30,
        alt: 'agyield'
    }"
>

    <div class="col-8 col-md-6 header-col px-2">
        <div class="logo"><img alt="AgYield" src="assets/img/brand/logo-small.png" /></div>
        <div class="form-group col-md-6 float-start col-6 userDrpCls" *ngIf="showUserDropdown">
            <div class="ng-autocomplete form-control" id="userDropDown" *ngIf="showUserDropdown">
                <ng-autocomplete
                    [(ngModel)]="selectedOrgUser"
                    [data]="searchUserData"
                    [searchKeyword]="keyword"
                    (selected)="switchCustomer($event)"
                    (inputChanged)="searchUser($event)"
                    [itemTemplate]="itemTemplate"
                >
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.name" [ngStyle]="item.sup == 'SUP' && { color: 'red' }"></a>
                </ng-template>
            </div>
        </div>
        <div class="form-group float-start col-md-3 col-6 userYearCls">
            <select
                class="form-control year-select"
                id="year_angular"
                *ngIf="activeYears?.length > 0"
                (change)="setUser($event.target.value)"
            >
                <option *ngFor="let ct of activeYears" value="{{ ct.year }}" [selected]="ct.year == selectedYear">
                    {{ (selectedStockType == 'livestock' && this.global.getVal('isLivestock') == 1)? ct.year : ct.yearLabel }}
                </option>
            </select>
        </div>

        <div class="form-group float-start col-md-3 col-6 stockType">
            <select
                class="form-control stock-type-select"
                id="stock_type_angular"
                *ngIf="this.global.getVal('isLivestock') && this.global.getVal('isMoMasterChecked') == 0"
                (change)="setStockType($event.target.value)"
            >
                <option *ngFor="let st of stockTypes" value="{{ st.value }}" [selected]="st.value == selectedStockType">
                    {{ st.lable }}
                </option>
            </select>
        </div>
    </div>
    <ul class="nav navbar-nav d-flex flex-row ml-auto" ng-click="isOpen = false">
        <li
            *ngIf="notificationArr && notificationArr.length > 0; else emptyLi"
            class="nav-item dropdown open align-self-center me-2"
            dropdown
            placement="bottom right"
        >
            <a
                class="nav-link"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="true"
                dropdownToggle
                data-target="#navbarNotification"
                aria-controls="navbarNotification"
            >
                <i class="icon-bell"></i
                ><span *ngIf="getObjectLength(notificationArr) > 0" class="badge badge-pill badge-danger">{{
                    getObjectLength(notificationArr)
                }}</span></a
            >
            <div
                class="dropdown-menu dropdown-menu-right notif-dropdown"
                id="navbarNotification"
                *dropdownMenu
                aria-labelledby="simple-dropdown"
            >
                <a *ngFor="let nt of notificationArr" class="dropdown-item"
                    ><span>
                        {{ nt.message }}
                        {{ getDateWithFormat(nt.createdDtm.date) }}
                    </span>
                    <i class="fa fa-trash cursorCls redColor" (click)="removeNotification(nt.id)"></i
                ></a>
            </div>
        </li>
        <ng-template #emptyLi
            ><li class="nav-item dropdown open align-self-center mx-3" dropdown placement="bottom right">
                <i class="icon-bell"></i></li
        ></ng-template>
        <li class="nav-item dropdown open align-self-center" dropdown placement="bottom right">
            <a
                class="nav-link"
                data-toggle="dropdown"
                href="javascript:void(0)"
                role="button"
                aria-haspopup="true"
                aria-expanded="true"
                dropdownToggle
                data-target="#navbarDropdown"
                aria-controls="navbarDropdown"
            >
                <img src="{{ image }}" class="img-avatar mx-2" alt="admin@bootstrapmaster.com" *ngIf="showHeader" />
                <label class="userName pe-2">
                    <span *ngIf="this.global.getVal('userFirstName')">
                        {{getFullName()}}</span
                    ></label
                >
            </a>

            <div id="navbarDropdown" class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
                <a class="dropdown-item" (click)="showMyAccount()"><i class="fa fa-user"></i>My Profile</a>
                <a class="dropdown-item" (click)="showRenewPage()" *ngIf="isFreeTrial"><i class="fa fa-user"></i>Upgrade Account</a>
                <a class="dropdown-item" (click)="logout()" id="logout"><i class="fa fa-lock"></i> Logout</a>
            </div>
        </li>
    </ul>
</c-header>
<!-- Main Body -->
<div class="app-body" *ngIf="isUserLoaded" >
    <!-- Main content -->
    <app-sidebar [navItems]="navItems"></app-sidebar>
    <main class="main">
        <!-- Breadcrumb -->
        <!-- breaking change 'cui-breadcrumb' -->
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
        <!-- /.container-fluid -->
    </main>
</div>
<!-- Main Body -->

<c-footer>
    <span class="ml-auto" *ngIf="isUserLoaded">Powered by AgYield</span>
</c-footer>
