import { Component,Input } from '@angular/core';
import { Globals } from '../../modules/shared/common/globals';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
    @Input() navItems:any;
    global: Globals;
    constructor() {
      this.global = Globals.getInstance();
  }

    toggleSubMenu(menuItem: any) {
        this.navItems.forEach((menu:any, index:any) => {
          if(menu.name != menuItem.name) menu.expanded = false;
        });
        menuItem.expanded = !menuItem.expanded;
    }
  toggleSubMenu1(submenuItem: any) {
    submenuItem.expanded = !submenuItem.expanded;
  }
  closeAllMenus() {
    this.navItems.forEach((menu: any) => {
      menu.expanded = false;
      if (menu.children) {
        menu.children.forEach((submenu: any) => {
          submenu.expanded = false;
        });
      }
    });
  }
}
