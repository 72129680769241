/** Util is used to listed all the common utility function(s) */
import swal from 'sweetalert2';
import { FormGroup } from '@angular/forms';
import { DecimalPipe, formatDate, formatNumber } from '@angular/common';
import * as _ from 'underscore';
import { AlertMessage, ConfirmationMessage } from '../../../models/alert.message.model';
import { ayObject, ExpirationDate } from '../interface/shared-interface';
import { DecimalFormatPipe } from '../pipes/decimal-format.pipe';
import { Globals } from './globals';
import { AppConstants } from './constants';
declare const InstallTrigger: any;
declare const window: ayObject;
declare const safari: any;
declare const document: any;

export enum browserType {
    Firefox = 'Firefox',
    IE = 'IE',
    Opera = 'Opera',
    Edge = 'Edge',
    Chrome = 'Chrome',
    OldChrome = 'OldChrome',
    Safari = 'Safari'
}

export interface browserMessage {
    message?: string;
    link?: string;
}
const facetNameArray = [AppConstants.FACED_NAME_CUSTOM_CONTRACT, AppConstants.FACED_NAME_FIRM_OFFER];

export class Util {
    static browserType: browserType;

    /** showHtmlMessage function will be used to display the Html message*/
    static showHtmlMessage = (alertMessage: AlertMessage): void => {
        swal.fire({
            icon: alertMessage.type, // warning/error/info/success
            title: alertMessage.title,
            html: alertMessage.text,
            confirmButtonText: alertMessage.confirmButtonText
        });
    };

    /** showTextMessage function will be used to display the text message*/
    static showTextMessage = (alertMessage: AlertMessage, showOk = true): void => {
        swal.fire({
            icon: alertMessage.type, // warning/error/info/success
            title: alertMessage.title,
            text: alertMessage.text,
            confirmButtonText: alertMessage.confirmButtonText,
            showConfirmButton: showOk
        }).then(() => {
            // if user has provided callBack then call the callback function
            if (alertMessage.callBack) {
                alertMessage.callBack();
            }
        });
    };

    /** showConfirmationDialog function will be used to display the confirmation dialog */
    static showConfirmationDialog = (option: ConfirmationMessage): void => {
        const outsideClick = option.allowOutsideClick === false ? option.allowOutsideClick : true;

        swal.fire({
            title: option.title,
            text: option.text,
            icon: option.type,
            showCancelButton: option.isShowCancelButton,
            confirmButtonText: option.confirmButtonText,
            cancelButtonText: option.cancelButtonText,
            html: option.html,
            allowOutsideClick: outsideClick,
	    allowEnterKey:false
        }).then((result) => {
            if (result.value) {
                option.okCallBack();
            } else if (result.dismiss === swal.DismissReason.cancel) {
                option.cancelCallBack();
            } else if (result.dismiss === swal.DismissReason.esc) {
                option.cancelCallBack();
            }
        });
    };

    /** showConfirmationDialog function will be used to display the confirmation dialog */
    static showInputDialog = (option: ConfirmationMessage): void => {
        swal.fire({
            title: option.title,
            text: option.text,
            icon: option.type,
            showCancelButton: option.isShowCancelButton,
            confirmButtonText: option.confirmButtonText,
            cancelButtonText: option.cancelButtonText,
            html: option.html,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showLoaderOnConfirm: false
        }).then((result) => {
            if (result.value !== undefined) {
                option.okCallBack(result.value);
            } else if (result.dismiss === swal.DismissReason.cancel) {
                option.cancelCallBack();
            }
        });
    };

      /** showRenewUpgradePlanDialog function will be used to display the confirmation dialog */
      static showRenewUpgradePlanDialog = (option: ConfirmationMessage): void => {
        swal.fire({
            title: option.title,
            text: option.text,
            icon: option.type,
            showCancelButton: option.isShowCancelButton,
            cancelButtonText: option.cancelButtonText,
            html: option.html,
            confirmButtonText: option.finishRenewUpgradeButtonText,
            allowOutsideClick: Boolean,
        }).then((result) => {
            if (result.value !== undefined) {
                option.okCallBack();
            } else if (result.dismiss === swal.DismissReason.cancel) {
                option.cancelCallBack();
            }
        });
    };

    /** notBlank function will be used to check the given input has valid value or not */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static notBlank(val: any): boolean {
        return val === undefined || val === null || (typeof val === 'string' && val.trim() === '') ? false : true;
    }

    /** numberWithCommas function will be used to convert given input with commas */
    static numberWithCommas(x: string): string {
        return Util.notBlank(x)
            ? x
                  .toString()
                  .split('.')[0]
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (x.toString().split('.')[1] ? '.' + x.toString().split('.')[1] : '')
            : x;
    }

    /* to convert date to mm/dd/yyyy format*/
    static convertDate(str: string): string {
        const date = new Date(str);
        const month = ('' + (date.getMonth() + 1)).slice(-2);
        const day = ('' + date.getDate()).slice(-2);
        return [month, day, date.getFullYear()].join('/');
    }

    /** removeTrailingZerosAndDots function will be used to remove the trailing zeros and dots */
    static removeTrailingZerosAndDots(n: string): string {
        return Util.notBlank(n)
            ? n
                  .toString()
                  .replace(/(\.[0-9]*?)0+$/, '$1')
                  .replace(/\.$/, '')
            : n;
    }

    /** removeTrailingZeros function will be used to remove the trailing zeros */
    static removeTrailingZeros(num: number | string, floatFixedDigit: number, removeTrailingZero: boolean = true): string {
        let temp = 0;
        num = Number(num);

        if (!removeTrailingZero) {
            return num.toFixed(floatFixedDigit);
        } else {
            num = num.toFixed(7);
            const factor = Math.pow(10, floatFixedDigit);
            const numberArr = num.toString().split('.');
            const decimal = parseFloat(0 + '.' + numberArr[1]);
            if (!isNaN(decimal)) {
                temp = Math.round(decimal * factor) / factor;
                const temp1 = temp.toString().split('.');
                if (temp1[1] === undefined && parseInt(temp1[0], 10) !== 1) {
                    return numberArr[0];
                } else if (parseInt(temp1[0], 10) === 1) {
                    return (parseInt(numberArr[0], 10) + parseInt(temp1[0], 10)).toString();
                }
                return numberArr[0] + '.' + temp1[1];
            }
            return numberArr[0];
        }
    }

    /** monthToNumber function will be used to convert the month text to number  */
    static monthToNumber(month: number): string {
        const monthArr: ayObject = {};
        monthArr.January = 1;
        monthArr.February = 2;
        monthArr.March = 3;
        monthArr.April = 4;
        monthArr.May = 5;
        monthArr.June = 6;
        monthArr.July = 7;
        monthArr.August = 8;
        monthArr.September = 9;
        monthArr.October = 10;
        monthArr.November = 11;
        monthArr.December = 12;
        return monthArr[month];
    }

    /** monthToNumber function will be used to convert the month text to number  */
    static getmonthNumber(month: string): number {
        const dateHash: ayObject = {
            Jan: 1,
            Feb: 2,
            Mar: 3,
            Apr: 4,
            May: 5,
            Jun: 6,
            Jul: 7,
            Aug: 8,
            Sep: 9,
            Oct: 10,
            Nov: 11,
            Dec: 12
        };
        month = dateHash[month];
        if (Number(month) < 10) {
            month = '0' + month;
        }
        return Number(month);
    }

    /** monthToNumber function will be used to convert the month text to number  */
    static getMonthNumberFromFullMonthName(month: string): number {
        const dateHash: ayObject = {
            January: 1,
            February: 2,
            March: 3,
            April: 4,
            May: 5,
            June: 6,
            July: 7,
            August: 8,
            September: 9,
            October: 10,
            November: 11,
            December: 12
        };
        month = dateHash[month];
        if (Number(month) < 10) {
            month = '0' + month;
        }
        return Number(month);
    }
    /* monthToNumber function will be used to convert the month text to number without zero(0) */

    static getMonthNumberFromMonthNameWithoutZero(month: string): number {
        const dateHash: ayObject = {
            January: 1,
            February: 2,
            March: 3,
            April: 4,
            May: 5,
            June: 6,
            July: 7,
            August: 8,
            September: 9,
            October: 10,
            November: 11,
            December: 12
        };
        month = dateHash[month];
        return Number(month);
    }

    static getShortMonthName(monthNum: string): string {
        const month: ayObject = {};
        month[1] = 'Jan';
        month[2] = 'Feb';
        month[3] = 'Mar';
        month[4] = 'Apr';
        month[5] = 'May';
        month[6] = 'Jun';
        month[7] = 'Jul';
        month[8] = 'Aug';
        month[9] = 'Sept';
        month[10] = 'Oct';
        month[11] = 'Nov';
        month[12] = 'Dec';
        return month[Number(monthNum)];
    }

    static weekOfMonth(date: Date): number {
        return Math.ceil(date.getDate() / 7);
    }

    static getFullMonthName(monthNum: number): string {
        const month: ayObject = {};
        month[1] = 'January';
        month[2] = 'February';
        month[3] = 'March';
        month[4] = 'April';
        month[5] = 'May';
        month[6] = 'June';
        month[7] = 'July';
        month[8] = 'August';
        month[9] = 'September';
        month[10] = 'October';
        month[11] = 'November';
        month[12] = 'December';
        return month[monthNum];
    }

    // Function to show Loader
    static showLoading(): void {
        const global = Globals.getInstance();
        global.getVal('loader').show();
    }
    // Function to hide Loader
    static hideLoading(): void {
        const global = Globals.getInstance();
        global.getVal('loader').hide();
    }

    // Function to get Plan name from plan id
    static getPlanNameFromId(planId: number): string {
        const plan: ayObject = {};
        plan[2] = 'AgYield Pro';
        plan[10] = 'AgYield';
        return plan[planId];
    }

    // Function to remove element from given index
    static removeElementtAt(arr: any[], index: number): any[] {
        if (index > -1) {
            arr.splice(index, 1);
        }

        return arr;
    }

    static formatValWithOUtDollar(Value: string): string {
        const decimalPipe = new DecimalFormatPipe();
        let returnValue = '';
        returnValue = decimalPipe.transform(Value, 2, false);
        return returnValue;
    }
    static formatNumber(value: number, showInBraces: boolean = true, format: string = '1.2-3'): string | null {
        let returnValue;
        let parts = format.split('.');
        parts = parts[1].split('-');

        if (value < 0) {
            if (showInBraces) {
                value = value * -1;
                returnValue = value.toFixed(Number(parts[1]));
                returnValue = '(' + returnValue + ')';
            } else {
                returnValue = value.toFixed(Number(parts[1]));
            }
        } else {
            returnValue = value.toFixed(Number(parts[1]));
        }
        return returnValue;
    }

    static formatNumberWithRound(value: number, format: string = '1.2-3', localId: string = 'en-US',): string | null {
        return formatNumber(value, localId, format);
    }

    static sortByFormattedNumber(columnName: string, list: any[], sortByDefault: string): any {
        const rawData = list.slice();
        rawData.sort((a, b) => {
            if (sortByDefault === 'asc') {
                if (parseFloat(a[columnName]) < parseFloat(b[columnName])) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (parseFloat(a[columnName]) > parseFloat(b[columnName])) {
                    return 1;
                } else {
                    return -1;
                }
            }
        });
        return rawData;
    }

    static sortByFormattedControl(columnName: string, list: any[], sortByDefault: string): any {
        const rawData = list.slice();
        rawData.sort((a, b) => {
            if (sortByDefault === 'asc') {
                if (parseFloat(a.controls[columnName].value) < parseFloat(b.controls[columnName].value)) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (parseFloat(a.controls[columnName].value) > parseFloat(b.controls[columnName].value)) {
                    return 1;
                } else {
                    return -1;
                }
            }
        });
        return rawData;
    }

    static blank(val: number | string): boolean {
        return val === undefined || val == null || (typeof val === 'string' && val.trim() === '') ? true : false;
    }

    static getNetPrice(HTAprice: string, premium: string, HTAbasis: string, truckingFees: string, HTAfee: string): number {
        let netPrice;
        netPrice = parseFloat(HTAbasis) + parseFloat(HTAprice) + parseFloat(premium) - (parseFloat(truckingFees) + parseFloat(HTAfee));
        if (isNaN(parseFloat(netPrice.toString()))) {
            netPrice = 0.0;
        }
        return netPrice;
    }

    // Function exacpeRegex string
    static escapeRegExp(str: string): string {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    /* Function to replace all occurrences of string in another string */
    static replaceAll(str: string, term: string, replacement: string): string {
        return str.replace(new RegExp(this.escapeRegExp(term), 'g'), replacement);
    }

    static makeid(length: number): string {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    static firstLetterCaps(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    static capitalLetterAddSpace(str: string): string {
        str = str.charAt(0).toUpperCase() + str.slice(1);
        return str.replace(/([A-Z])/g, ' $1').trim();
    }

    static isIpad(): boolean {
        const ua = window.navigator.userAgent;
        if (ua.indexOf('iPad') > -1) {
            return true;
        }

        if (ua.indexOf('Macintosh') > -1) {
            try {
                document.createEvent('TouchEvent');
                return true;
            } catch (e) {}
        }

        return false;
    }

    static isMobile(): boolean {
        return (
            // eslint-disable-next-line max-len
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iPad|iPhone|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                navigator.userAgent
            ) ||
            // eslint-disable-next-line max-len
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                navigator.userAgent.substr(0, 4)
            )
        );
    }

    static parseDateFromDateTime(dateTime: string): string {
        const dateTimeSpaceSplit = dateTime.split(' ');
        const dateDashSplit = dateTimeSpaceSplit[0].split('-');
        return dateDashSplit[1] + '/' + dateDashSplit[2] + '/' + dateDashSplit[0];
    }

    static getMarketUOM(cropTypeId: number, CropTypeParent: number, display = 0, cropProductionUnit = ''): string {
        let currency;
        let currencyunit;
        if (cropTypeId === 8 || CropTypeParent === 8) {
            currency = '$CAN/mt';
            currencyunit = '$CAN';
        } else if (cropTypeId === 7 || CropTypeParent === 7) {
            currency = '$/lbs';
            currencyunit = '$';
        } else {
            currency = '$/bu';
            currencyunit = '$';
        }
        if (CropTypeParent === null) {
            const global = Globals.getInstance();
            const appCurrencyUnit = global.getVal('currencyUnit');
            currency = appCurrencyUnit + '/' + cropProductionUnit;
        }
        if (display === 1) {
            return currencyunit;
        } else {
            return currency;
        }
    }

    /**
     * Function to return crop name based on crop type
     *
     * @param cropTypeId
     * @returns string
     * @author Manesh Raval
     */
    static getCropName(cropTypeId: number): string {
        const cropTypes = [];
        cropTypes[1] = 'Corn';
        cropTypes[2] = 'HRW Wheat';
        cropTypes[3] = 'HRS Wheat';
        cropTypes[4] = 'Oats';
        cropTypes[5] = 'Soybeans';
        cropTypes[6] = 'SRW Wheat';
        cropTypes[7] = 'Cotton';
        cropTypes[8] = 'Rapeseed Canola';

        return cropTypes[cropTypeId];
    }

    /**
     * Function to return futures/options contract string
     *
     * @param list
     * @returns string
     * @author Karan Sejwani
     */
    static getContractString(list: ayObject): string {
        let contract = '';
        const cropTypeId = list.cropTypeId;
        const optionsMap: any = [];
        optionsMap.P = ' PUT';
        optionsMap.C = ' CALL';
        if ('csoMonth' in list === true) {
            contract =
                list.month +
                ' ' +
                list.cropType +
                ' ' +
                (list.lotSize === 'Mini' ? ' Mini' : '') +
                ((list.cropTypeId.toString() === '8' || (list.parent !== 'undefined' && list.parent.toString() === '8')) &&
                typeof list.isPut !== 'undefined'
                    ? ' $CAN '
                    : typeof list.isPut !== 'undefined'
                    ? ' $'
                    : '') +
                (list.strike ? Util.formatValWithOUtDollar(list.strike) + ' CSO ' + optionsMap[list.isPut] : '');
        } else {
            if (list.CONTRACT_STR !== '') {
                if (cropTypeId === AppConstants.SOYBEANS_OIL_CROP_TYPE_ID) {
                    let strikePrice = 0;
                    if (list.strike) {
                        strikePrice = list.strike * 100;
                    }
                    contract =
                        list.year +
                        ' ' +
                        list.CONTRACT_STR +
                        ' ' +
                        (parseInt(list.shortDated) === 1 ? 'SD ' : '') +
                        list.cropType +
                        (list.lotSize === 'Mini' ? ' Mini' : ' ') +
                        (list.strike ? Util.formatValWithOUtDollar(strikePrice.toString()) + 'c' + optionsMap[list.isPut] : '');
                } else {
                    contract =
                        list.year +
                        ' ' +
                        list.CONTRACT_STR +
                        ' ' +
                        (parseInt(list.shortDated) === 1 ? 'SD ' : '') +
                        list.cropType +
                        (list.lotSize === 'Mini' ? ' Mini' : '') +
                        ((list.cropTypeId.toString() === AppConstants.CANOLA_CROP_TYPE_ID.toString() ||
                            (list.parent !== 'undefined' && list.parent.toString() === AppConstants.CANOLA_CROP_TYPE_ID.toString())) &&
                        typeof list.isPut != 'undefined'
                            ? ' $CAN '
                            : typeof list.isPut != 'undefined'
                            ? ' $'
                            : '') +
                        (list.strike ? Util.formatValWithOUtDollar(list.strike) + optionsMap[list.isPut] : '');
                }
            } else {
                if (cropTypeId.toString() === AppConstants.SOYBEANS_OIL_CROP_TYPE_ID.toString()) {
                    let strikePrice = 0;
                    if (list.strike) {
                        strikePrice = list.strike * 100;
                    }
                    contract =
                        list.year +
                        ' ' +
                        list.month +
                        ' ' +
                        (parseInt(list.shortDated) === 1 ? 'SD ' : '') +
                        list.cropType +
                        (list.lotSize === 'Mini' ? ' Mini' : ' ') +
                        (list.strike ? Util.formatValWithOUtDollar(strikePrice.toString()) + 'c' + optionsMap[list.isPut] : '');
                } else {
                    contract =
                        list.year +
                        ' ' +
                        list.month +
                        ' ' +
                        (parseInt(list.shortDated) === 1 ? 'SD ' : '') +
                        list.cropType +
                        (list.lotSize === 'Mini' ? ' Mini' : '') +
                        ((list.cropTypeId.toString() === AppConstants.CANOLA_CROP_TYPE_ID.toString() ||
                            (list.parent !== 'undefined' && list.parent.toString() === AppConstants.CANOLA_CROP_TYPE_ID.toString())) &&
                        typeof list.isPut != 'undefined'
                            ? ' $CAN '
                            : typeof list.isPut !== 'undefined'
                            ? ' $'
                            : '') +
                        (list.strike ? Util.formatValWithOUtDollar(list.strike) + optionsMap[list.isPut] : '');
                }
            }
        }

        return contract;
    }

    /**
     * Function to decide lotSize based on crop type.
     *
     * @param cropTypeId
     * @returns number
     * @author Manesh Raval
     */
    static getLotSizeBasedOnCropType(cropTypeId: number, selectedLotSize: number): number {
        let lotSize = 5000;
        if (cropTypeId === Number(AppConstants.CANOLA)) {
            lotSize = 20;
        } else if (cropTypeId === Number(AppConstants.COTTON)) {
            lotSize = 50000;
        } else if (selectedLotSize === 1000) {
            lotSize = 1000;
        }

        return lotSize;
    }

    public static resetForm(form: FormGroup): FormGroup {
        Object.keys(form.controls).forEach((key) => {
            form.controls[key].setValue('');
        });
        return form;
    }

    public static markAllFieldTouched(form: FormGroup): FormGroup {
        Object.keys(form.controls).forEach((key) => {
            form.controls[key].markAsTouched();
        });
        return form;
    }

    /**
     * Function get browser name
     *
     * @returns string
     * @author Manesh Raval
     */
    public static getBrowser(): string {
        let browser = '';
        const opr = 'opr';
        const opera = 'opera';

        browser =
            (!!window[opr] && !!window[opr].addons) || !!window[opera] || navigator.userAgent.indexOf(' OPR/') >= 0 ? 'Opera' : browser;

        // Firefox 1.0+
        browser = typeof InstallTrigger !== 'undefined' ? 'Firefox' : browser;

        // Safari 3.0+ "[object HTMLElementConstructor]"
        browser =
            /constructor/i.test(window.HTMLElement.toString()) ||
            (function(p) {
                return p.toString() === '[object SafariRemoteNotification]';
            })(!window.safari || safari.pushNotification)
                ? 'Safari'
                : browser;

        // Internet Explorer 6-11
        browser = false || !!document.documentMode ? 'IE' : browser;

        // Edge 20+
        browser = navigator.userAgent.indexOf('Chrome') !== -1 && navigator.userAgent.indexOf('Edg') !== -1 ? 'Edge' : browser;

        // Chrome 1+
        browser = !!window.chrome && !!window.chrome.webstore ? 'OldChrome' : browser;
        // If isChrome is undefined, then use:
        browser = browser.length === 0 && !!window.chrome && window.navigator.vendor === 'Google Inc.' ? 'Chrome' : browser;

        return browser;
    }

    /**
     * Function to get message if the browser is not supported
     *
     * @returns browserMessage
     * @author Manesh Raval
     */
    public static getBrowserMessage(): browserMessage {
        const message: browserMessage = {};
        const browser = Util.getBrowser();

        if (browser === browserType.IE || browser === browserType.Firefox || browser === browserType.Opera) {
            message.message =
                'You are using ' +
                browser +
                ' browser. AgYield Revenue Management may not work properly in this browser. ' +
                'Please switch to Chrome, Safari or Edge latest version.';
        } else if (browser === browserType.OldChrome) {
            message.message = 'You are using older version of Chrome. Please update to latest version of Chrome.';
        }
        return message;
    }

    /**
     * Get Message and respective link for enabling cookies
     *
     * @returns browserMessage
     * @author Manesh Raval
     */
    public static getCookiesMessage(): browserMessage {
        const message: browserMessage = {};
        const browser = Util.getBrowser();
        message.message = 'In your browser cookies are disabled. Please follow steps in the link';
        if (browser === browserType.Chrome) {
            message.link = 'https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DDesktop';
        } else if (browser === browserType.Edge) {
            message.link = 'https://www.whatismybrowser.com/guides/how-to-enable-cookies/edge';
        } else if (browser === browserType.Safari) {
            // eslint-disable-next-line max-len
            message.link =
                'https://www.wikihow.com/' +
                'Enable-Cookies-in-Safari#:~:text=Safari%20Enable%20' +
                'Cookies%201%20Open%20Safari.%202%20Click,5%20Check%20the%20%22Always%20allow%22%20box.%20See%20More.?' +
                'msclkid=08df7ff7bb2b11ec91b4f9d2cf7d40fd';
        }

        return message;
    }

    /**
     * Function get number in format
     *
     * @returns string
     * @author Riyan Sheikh
     */
    static formatPortfolioNumber(value: number, showInBraces: boolean = true, format: string = '1.2-3'): string {
        const decimalPipe = new DecimalPipe('en-US');
        let returnValue;
        if (value < 0) {
            if (showInBraces) {
                value = value * -1;
                returnValue = decimalPipe.transform(value, format);
                returnValue = '(' + returnValue + ')';
            } else {
                returnValue = decimalPipe.transform(value, format);
            }
        } else {
            returnValue = decimalPipe.transform(value, format);
        }
        if (returnValue === null) {
            returnValue = '';
        }
        return returnValue;
    }

    static sortByDate(columnName: string, list: any[], sortByDefault: string): any {
        const rawData = list.slice();
        rawData.sort((a, b) => {
            const dateTimeSpaceSplit = a[columnName].split(' ');
            const dateDashSplit = dateTimeSpaceSplit[0].split('-');
            a = dateDashSplit[1] + '/' + dateDashSplit[2] + '/' + dateDashSplit[0];
            const bDateTimeSpaceSplit = b[columnName].split(' ');
            const bDateDashSplit = bDateTimeSpaceSplit[0].split('-');
            b = bDateDashSplit[1] + '/' + bDateDashSplit[2] + '/' + bDateDashSplit[0];
            if (sortByDefault === 'asc') {
                if (a < b) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a > b) {
                    return 1;
                } else {
                    return -1;
                }
            }
        });
        return rawData;
    }

    static getExpDisplay = (expObj: ExpirationDate): string => {
        let month = '';
        const dateFromTimeSplitDate1 = expObj?.expirationDate?.date?.split(' ');
        if (dateFromTimeSplitDate1) {
            const dateFromSplitDate1 = dateFromTimeSplitDate1[0]?.split('-');
            const expiryDate = formatDate(
                dateFromSplitDate1[1] + '/' + dateFromSplitDate1[2] + '/' + dateFromSplitDate1[0],
                'MM/dd/yyyy',
                'en-US'
            );
            const weekNum = Util.weekOfMonth(new Date(expiryDate));
            const objDate = new Date(expiryDate);
            const locale = 'en-us';
            if (expObj.optionType !== 'W') {
                const sdStr = expObj.shortDated ? ' SD ' : ' ';

                objDate.setMonth(objDate.getMonth() + 1);
                month = objDate.toLocaleString(locale, { month: 'long' });
                return _.escape(month) + sdStr + objDate.getFullYear() + ' ' + _.escape(expiryDate);
            } else {
                month = objDate.toLocaleString(locale, { month: 'long' });
                return 'Week ' + weekNum + ' ' + _.escape(month) + ' ' + _.escape(expiryDate);
            }
        }
        return '';
    };


    static getExpDisplayPenOption = (expObj: ExpirationDate): string => {
        let month = '';
        const dateFromTimeSplitDate1 = expObj?.expirationDate?.date?.split(' ');
        if (dateFromTimeSplitDate1) {
            const dateFromSplitDate1 = dateFromTimeSplitDate1[0]?.split('-');
            const expiryDate = formatDate(
                dateFromSplitDate1[1] + '/' + dateFromSplitDate1[2] + '/' + dateFromSplitDate1[0],
                'MM/dd/yyyy',
                'en-US'
            );
            const weekNum = Util.weekOfMonth(new Date(expiryDate));
            const objDate = new Date(expiryDate);
            const locale = 'en-us';
            if (expObj.optionType !== 'W') {
                const sdStr = expObj.shortDated ? ' SD ' : ' ';
                month = objDate.toLocaleString(locale, { month: 'long' });
                return _.escape(month) + sdStr + objDate.getFullYear() + ' ' + _.escape(expiryDate);
            } else {
                month = objDate.toLocaleString(locale, { month: 'long' });
                return 'Week ' + weekNum + ' ' + _.escape(month) + ' ' + _.escape(expiryDate);
            }
        }
        return '';
    };


    static facetName(key: any, facet: any): any {
        if (facetNameArray.includes(facet)) {
            key = this.capitalLetterAddSpace(key);
            return key;
        }
        return key;
    }

    // Replace coma for numeric value.
    static replaceComma(strVal: string): number {
        if (strVal.indexOf(',') > -1) {
            return parseFloat(strVal.replace(',', ''));
        } else {
            return parseFloat(strVal);
        }
    }
}