const indent = '\u2008\u2008\u2008\u2008\u2008\u2008\u2008\u2008\u2008\u2008\u2008\u2008\u2008';

/* Admin Menu Combinations Based On Plan */

const adminMenu = [
    {
        name: 'Users',
        url: '/user-list',
        icon: 'fa fa-user-circle-o'
    },
    {
        name: 'Organization',
        url: '/organization',
        icon: 'fa fa-sitemap'
    },
    {
        name: 'Activity History',
        url: '/activity-history',
        icon: 'icon-puzzle'
    },
    // {
    //     name: 'Crop Defaults',
    //     url: '/crop-default',
    //     icon: 'fa-th-list'
    // },
    {
        name: 'Crop Defaults',
        url: '/crop-default-new',
        icon: 'fa-th-list'
    },
    {
        name: 'Global Strategy',
        url: '/global-strategy',
        icon: 'fa fa-bullseye'
    },
    {
        name: 'News',
        url: '/news',
        icon: 'fa fa-newspaper-o'
    },
    {
        name: 'Commentary',
        url: '/commentary',
        icon: 'fa fa-commenting'
    },
    {
        name: 'Simulator Users',
        url: '/simulator-users',
        icon: 'fa fa-user-o'
    },
    {
        name: 'Simulator Games',
        url: '/simulator-games',
        icon: 'fa fa-gamepad'
    },
    {
        name: 'Crop Type',
        url: '/croptype',
        icon: 'fa fa-list-ul'
    },
    {
        name: 'Office Code',
        url: '/officecode',
        icon: 'fa fa-briefcase'
    },
    {
        name: 'Discount Code',
        url: '/discount-code',
        icon: 'fa fa-tag'
    }
];
const staffMenu = [
    {
        name: 'Users',
        url: '/user-list',
        icon: 'fa fa-user-circle-o'
    },
    {
        name: 'Organization',
        url: '/organization',
        icon: 'fa fa-sitemap'
    },
    {
        name: 'Activity History',
        url: '/activity-history',
        icon: 'icon-puzzle'
    },
    // {
    //     name: 'Crop Defaults',
    //     url: '/crop-default',
    //     icon: 'fa-th-list'
    // },
    {
        name: 'Crop Defaults',
        url: '/crop-default-new',
        icon: 'fa-th-list'
    },
    {
        name: 'Global Strategy',
        url: '/global-strategy',
        icon: 'fa fa-bullseye'
    },
    {
        name: 'News',
        url: '/news',
        icon: 'fa fa-newspaper-o'
    },
    {
        name: 'Simulator Users',
        url: '/simulator-users',
        icon: 'fa fa-user-o'
    },
    {
        name: 'Simulator Games',
        url: '/simulator-games',
        icon: 'fa fa-gamepad'
    },
    {
        name: 'Crop Type',
        url: '/croptype',
        icon: 'fa fa-list-ul'
    },
    {
        name: 'Discount Code',
        url: '/discount-code',
        icon: 'fa fa-tag'
    },
    {
        name: 'Commentary',
        url: '/commentary',
        icon: 'fa fa-commenting'
    }
];
const moAdminMenu = [
    {
        name: 'Users',
        url: '/user-list',
        icon: 'fa fa-user-circle-o'
    },
    {
        name: 'Customers',
        url: '/customers',
        icon: 'fa fa-id-card-o'
    },
    {
        name: 'Organization',
        url: '/organization',
        icon: 'fa fa-sitemap'
    },
    {
        name: 'Advisor',
        url: '/advisors',
        icon: 'fa fa-user-md'
    },
    {
        name: 'Activity History',
        url: '/activity-history',
        icon: 'icon-puzzle'
    }
];
const advAdminMenu = [
    {
        name: 'Users',
        url: '/user-list',
        icon: 'fa fa-user-circle-o'
    },
    {
        name: 'Customers',
        url: '/customers',
        icon: 'fa fa-id-card-o'
    },
    {
        name: 'Organization',
        url: '/organization',
        icon: 'fa fa-sitemap'
    }
];
const advStfMenu = [
    {
        name: 'Customers',
        url: '/customers',
        icon: 'fa fa-id-card-o'
    }
];
const endCustomerMenu = [
    {
        name: 'Users',
        url: '/user-list',
        icon: 'fa fa-user-circle-o'
    },
    {
        name: 'Organization',
        url: '/organization',
        icon: 'fa fa-sitemap'
    }
];
const endMbpCustomerMenu = [
    {
        name: 'Users',
        url: '/user-list',
        icon: 'fa fa-user-circle-o'
    }
];
const moLiteAdminMenu = [
    {
        name: 'Users',
        url: '/user-list',
        icon: 'fa fa-user-circle-o'
    },
    {
        name: 'Customers',
        url: '/customers',
        icon: 'fa fa-id-card-o'
    },
    {
        name: 'Organization',
        url: '/organization',
        icon: 'fa fa-sitemap'
    }
];

/* Admin/MBP Menu Combinations Based On Plan */
const mbpMenu = [
    {
        name: 'Manage Bushel Programs',
        url: '/mbp',
        icon: 'fa fa-sliders'
    },
    {
        name: 'Backtesting MBP',
        url: '/backtesting',
        icon: 'fa fa-server'
    },
    {
        name: 'Portfolio Questionnaire',
        url: '/portfolio-questionnaire',
        icon: 'fa fa-question-circle'
    },
    {
        name: 'Manage Portfolio',
        url: '/portfolio-mbp',
        icon: 'fa fa-address-card'
    }
];

/* Resource Menu Combinations Based On Plan */
const resourceMenu = [
    {
        name: ' Market Quotes',
        icon: 'fa fa-th-large',
        url: '\u2008',
        children: [
            {
                name: indent + 'Futures & Options Quotes',
                url: '/resources/FOQuotesChart'
            },
            {
                name: indent + 'Cash Grain Quotes',
                url: '/resources/CashGrainQuotes'
            }
        ]
    },
    {
        name: 'News',
        url: '/resource/news',
        icon: 'fa fa-newspaper-o'
    },
    {
        name: 'CropProphet',
        url: '\u2008',
        icon: 'fa fa-leaf',
        children: [
            {
                name: indent + 'Introduction',
                url: '/resources/CropProphetIntroduction'
            },
            {
                name: indent + 'Yield Forecast History',
                url: '/resources/YieldForecastHistory'
            },
            {
                name: indent + 'CropProphet Website',
                url: '/resources/CropProphetWebsite'
            }
        ]
    },
    {
        name: 'Market Commentary',
        icon: 'fa fa-microphone',
        url: '\u2008',
        children: [
            {
                name: indent + 'Daily Grain Commentary',
                url: '/resources/daily-commentary'
            },
            {
              name: indent + 'Special Reports',
              url: '/resources/BasicSpecialReports'
            },            
            {
                name: indent + 'Evening Livestock Commentary',
                url: '/resources/evening-commentary'
            },
            {
                name: indent + 'Hedge Recommendations',
                url: '/resources/HedgeRecommendations'
            },
            {
                name: indent + 'Video/Audio Commentary',
                url: '/resources/HedgeReportsAudio'
            }
        ]
    },
    {
        name: 'USDA Reports',
        icon: 'fa fa-file-pdf-o',
        url: '\u2008',
        children: [
            {
                name: indent + 'Export Inspections',
                url: '/resources/ExportInspections'
            },
            {
                name: indent + 'Crop Progress Reports',
                url: '/resources/CropProgressReport'
            },
            {
                name: indent + 'EIA Ethanol',
                url: '/resources/EIAEthanol'
            },
            {
                name: indent + 'Weekly Export Sales (Table)',
                url: '/resources/WeeklyExportSales'
            },
            {
                name: indent + 'Weekly Export Sales (Highlights)',
                url: '/resources/WeeklyExportSalesHighlights'
            },
            {
                name: indent + 'WASDE Supply & Demand',
                url: '/resources/WASDESupplyDemand'
            },
            {
                name: indent + 'Quaterly Stocks',
                url: '/resources/QuaterlyStocks'
            },
            {
                name: indent + 'Stats Canada',
                url: '/resources/StatsCanada'
            },
            {
                name: indent + 'Acreage Report (June)',
                url: '/resources/AcreageReportJune'
            },
            {
                name: indent + 'Prospective Plantings (March)',
                url: '/resources/ProspectivePlantingsMarch'
            }
        ]
    }, {
      name: 'Education',
      icon: 'fa fa-pencil-square-o',
      url: '\u2008',
      children: [
          {
              name: indent + 'Cash Contracts',
              url: '/resources/CashContracts'
          },
          {
              name: indent + 'Futures',
              url: '/resources/Futures'
          },
          {
              name: indent + 'Options',
              url: '/resources/Options'
          },
          {
              name: indent + 'Margin',
              url: '/resources/Margin'
          },
          {
              name: indent + 'Crop Insurance',
              url: '/resources/CropIns'
          }
      ]
    }, {
      name: 'Commonly Used Strategies',
      icon: 'fa fa-comments-o',
      url: '\u2008',
      children: [
          {
              name: indent + 'Buy a Put',
              url: '/resources/BuyAPut'
          },
          {
              name: indent + 'Sell a Cash contract & Buy a Call',
              url: '/resources/SellaCashBuyaCall'
          },
          {
              name: indent + 'Sell a Cash contract & Buy call Spread',
              url: '/resources/SellACashBuyACallSpread'
          },
          {
              name: indent + 'Buy a put & Sell a Call (Callar)',
              url: '/resources/BuyAPutSellACall'
          }
      ]
    }, {
        name: 'Weather',
        icon: 'fa fa-cloud',
        url: '\u2008',
        children: [
            // {
            //  name: indent + 'Freese-Notis',
            // url: '/resources/FreeseNotis'
            // },
            {
                name: indent + 'Observed Precip',
                url: '/resources/ObservedPrecip'
            }
        ]
    },
    {
        name: 'Calculators',
        icon: 'fa fa-calculator',
        url: '\u2008',
        children: [
            {
                name: indent + 'Cattle Crush Calculator',
                url: '/resources/CattleCrushCalculator'
            },
            {
                name: indent + 'Insurance Comparision',
                url: '/resources/InsuranceComparision'
            }
        ]
    },
    {
        name: 'Tweets by @AgYield',
        url: '/resources/tweets',
        icon: 'fa fa-twitter'
    }
];

/* Resource Menu Combinations Based On Plan */
const resourceMenuExceptEduStratagy = [
    {
        name: ' Market Quotes',
        icon: 'fa fa-th-large',
        url: '\u2008',
        children: [
            {
                name: indent + 'Futures & Options Quotes',
                url: '/resources/FOQuotesChart'
            },
            {
                name: indent + 'Cash Grain Quotes',
                url: '/resources/CashGrainQuotes'
            }
        ]
    },
    {
        name: 'News',
        url: '/resource/news',
        icon: 'fa fa-newspaper-o'
    },
    {
        name: 'CropProphet',
        url: '\u2008',
        icon: 'fa fa-leaf',
        children: [
            {
                name: indent + 'Introduction',
                url: '/resources/CropProphetIntroduction'
            },
            {
                name: indent + 'Yield Forecast History',
                url: '/resources/YieldForecastHistory'
            },
            {
                name: indent + 'CropProphet Website',
                url: '/resources/CropProphetWebsite'
            }
        ]
    },
    {
        name: 'Market Commentary',
        icon: 'fa fa-microphone',
        url: '\u2008',
        children: [
            {
                name: indent + 'Daily Grain Commentary',
                url: '/resources/BasicConsultingServices'
            },
            {
                name: indent + 'Evening Livestock Comentary',
                url: '/resources/Livestock'
            },
            {
                name: indent + 'Hedge Recommendations',
                url: '/resources/HedgeRecommendations'
            },
            {
                name: indent + 'Video/Audio Commentary',
                url: '/resources/HedgeReportsAudio'
            }
        ]
    },
    {
        name: 'USDA Reports',
        icon: 'fa fa-file-pdf-o',
        url: '\u2008',
        children: [
            {
                name: indent + 'Export Inspections',
                url: '/resources/ExportInspections'
            },
            {
                name: indent + 'Crop Progress Reports',
                url: '/resources/CropProgressReport'
            },
            {
                name: indent + 'EIA Ethanol',
                url: '/resources/EIAEthanol'
            },
            {
                name: indent + 'Weekly Export Sales (Table)',
                url: '/resources/WeeklyExportSales'
            },
            {
                name: indent + 'Weekly Export Sales (Highlights)',
                url: '/resources/WeeklyExportSalesHighlights'
            },
            {
                name: indent + 'WASDE Supply & Demand',
                url: '/resources/WASDESupplyDemand'
            },
            {
                name: indent + 'Quaterly Stocks',
                url: '/resources/QuaterlyStocks'
            },
            {
                name: indent + 'Stats Canada',
                url: '/resources/StatsCanada'
            },
            {
                name: indent + 'Acreage Report (June)',
                url: '/resources/AcreageReportJune'
            },
            {
                name: indent + 'Prospective Plantings (March)',
                url: '/resources/ProspectivePlantingsMarch'
            }
        ]
    },
    {
        name: 'Weather',
        icon: 'fa fa-cloud',
        url: '\u2008',
        children: [
            // {
            //  name: indent + 'Freese-Notis',
            // url: '/resources/FreeseNotis'
            // },
            {
                name: indent + 'Observed Precip',
                url: '/resources/ObservedPrecip'
            }
        ]
    },
    {
        name: 'Calculators',
        icon: 'fa fa-calculator',
        url: '\u2008',
        children: [
            {
                name: indent + 'Cattle Crush Calculator',
                url: '/resources/CattleCrushCalculator'
            },
            {
                name: indent + 'Insurance Comparision',
                url: '/resources/InsuranceComparision'
            }
        ]
    },
    {
        name: 'Tweets by @AgYield',
        url: '/resources/tweets',
        icon: 'fa fa-twitter'
    }
];

/* Management Menu Combinations Based On Plan */

const managementMenu = [
    {
        name: 'Entities',
        url: '/entities',
        icon: 'fa fa-handshake-o'
    },
    {
        name: 'Fields',
        url: '/field',
        icon: 'fa fa-th'
    },
    {
        name: 'Crops',
        url: '/crops',
        icon: 'fa fa-pagelines'
    },
    {
        name: 'Pens',
        url: '/pens',
        icon: 'fa fa-object-ungroup'
    },
    // {
    //     name: 'Expenses',
    //     url: '/expense-crop',
    //     icon: 'fa fa-money'
    // },
    {
        name: 'Expenses',
        url: '/expense/crop',
        icon: 'fa fa-money'
    },
    {
        name: 'Expenses',
        url: '/pen-expense',
        icon: 'fa fa-money'
    },
    {
        name: 'Insurance',
        url: '/pen-insurance',
        icon: 'fa fa-file-text'
    },
    {
        name: 'Insurance',
        url: '/insurance',
        icon: 'fa fa-file-text'
    },
    {
        name: 'Transactions',
        url: '/pen-transactions',
        icon: 'fa fa-exchange'
    },
    {
        name: 'Transactions',
        url: '/transactions',
        icon: 'fa fa-exchange'
    }    
];

const managementBasicMenu = [
    {
        name: 'Crops',
        url: '/crops',
        icon: 'fa fa-pagelines'
    },
    // {
    //     name: 'Expenses',
    //     url: '/expense-crop',
    //     icon: 'fa fa-money'
    // },
    {
        name: 'Pens',
        url: '/pens',
        icon: 'fa fa-pen'
    },
    {
        name: 'Expenses',
        url: '/expense/crop',
        icon: 'fa fa-money'
    },
    {
        name: 'Expenses',
        url: '/pen-expense',
        icon: 'fa fa-money'
    },
    {
        name: 'Insurance',
        url: '/pen-insurance',
        icon: 'fa fa-file-text'
    },
    {
        name: 'Transactions',
        url: '/pen-transactions',
        icon: 'fa fa-exchange'
    },
    {
        name: 'Insurance',
        url: '/insurance',
        icon: 'fa fa-file-text'
    },
    {
        name: 'Transactions',
        url: '/transactions',
        icon: 'fa fa-exchange'
    }
];

const managementMbpMenu = [
    {
        name: 'Transactions',
        url: '/transactions',
        icon: 'fa fa-exchange'
    },
    {
        name: 'MBP Users',
        url: '/mbp-user',
        icon: 'fa fa-user'
    }
];

const managementSponsorMbpMenu = [
    {
        name: 'MBP Users',
        url: '/mbp-user',
        icon: 'fa fa-user'
    }
];

/* Performance Menu Combinations Based On Plan */

const PerformanceMenu = [
    {
        name: 'Account Summary',
        url: '/account-summary',
        icon: 'fa fa-list-alt'
    },
    {
        name: 'Account Summary',
        url: '/pen-account-summary',
        icon: 'fa fa-list-alt'
    },
    {
        name: 'Hedges Snapshot',
        url: '/hedges-snapshot',
        icon: 'fa fa-bar-chart'
    },
    {
        name: 'Performance Chart',
        url: '/performance-chart',
        icon: 'fa fa-area-chart'
    },
    {
        name: 'Profitability Outlook',
        url: '/profitability-outlook',
        icon: 'fa fa-line-chart'
    },
    {
        name: 'Athena',
        url: '/athena',
        icon: 'fa fa-balance-scale'
    },
    {
        name: 'Benchmarking',
        url: '/benchmarking',
        icon: 'fa fa-database'
    },
    {
        name: 'AgYield MBP',
        url: '/agyield-mbp',
        icon: 'fa fa-pie-chart'
    }
];

const PerformanceSupMenu = [
    {
        name: 'AgYield MBP',
        url: '/agyield-mbp',
        icon: 'fa fa-pie-chart'
    }
];

const PerformanceAthenaLockedMenu = [
    {
        name: 'Account Summary',
        url: '/account-summary',
        icon: 'fa fa-list-alt'
    },
    {
        name: 'Account Summary',
        url: '/pen-account-summary',
        icon: 'fa fa-list-alt'
    },
    {
        name: 'Hedges Snapshot',
        url: '/hedges-snapshot',
        icon: 'fa fa-bar-chart'
    },
    {
        name: 'Performance Chart',
        url: '/performance-chart',
        icon: 'fa fa-area-chart '
    },
    {
        name: 'Profitability Outlook',
        url: '/profitability-outlook',
        icon: 'fa fa-line-chart'
    },
    {
        name: 'Athena',
        url: '/athena',
        icon: 'fa fa-lock'
    },
    {
        name: 'Benchmarking',
        url: '/benchmarking',
        icon: 'fa fa-database'
    },
    {
        name: 'AgYield MBP',
        url: '/agyield-mbp',
        icon: 'fa fa-pie-chart'
    }
];

const PerformanceMbpMasterMenu = [
    {
        name: 'Account Summary',
        url: '/account-summary',
        icon: 'fa fa-list-alt'
    },
    {
        name: 'Account Summary',
        url: '/pen-account-summary',
        icon: 'fa fa-list-alt'
    },
    {
        name: 'Profitability Outlook',
        url: '/profitability-outlook',
        icon: 'fa fa-line-chart'
    },
    {
        name: 'Athena',
        url: '/athena',
        icon: 'fa fa-balance-scale'
    },
    {
        name: 'AgYield MBP',
        url: '/agyield-mbp',
        icon: 'fa fa-pie-chart'
    }
];

const PerformanceMbpMasterLiteMenu = [
    {
        name: 'Account Summary',
        url: '/account-summary',
        icon: 'fa fa-list-alt'
    },
    {
        name: 'Account Summary',
        url: '/pen-account-summary',
        icon: 'fa fa-list-alt'
    },
    {
        name: 'Profitability Outlook',
        url: '/profitability-outlook',
        icon: 'fa fa-line-chart'
    },
    {
        name: 'AgYield MBP',
        url: '/agyield-mbp',
        icon: 'fa fa-pie-chart'
    }
];

const PerformanceMbpMenu = [
    {
        name: 'AgYield MBP',
        url: '/agyield-mbp',
        icon: 'fa fa-pie-chart'
    }
];

const PerformanceMoMasterMenu = [
    {
        name: 'Account Summary',
        url: '/account-summary',
        icon: 'fa fa-list-alt'
    },
    {
        name: 'Account Summary',
        url: '/pen-account-summary',
        icon: 'fa fa-list-alt'
    },
    {
        name: 'Hedges Snapshot',
        url: '/hedges-snapshot',
        icon: 'fa fa-bar-chart'
    },
    {
        name: 'Performance Chart',
        url: '/performance-chart',
        icon: 'fa fa-area-chart'
    },
    {
        name: 'Profitability Outlook',
        url: '/profitability-outlook',
        icon: 'fa fa-line-chart'
    },
    {
        name: 'Benchmarking',
        url: '/benchmarking',
        icon: 'fa fa-database'
    }
];

export const menuList = {
    basicUser: [
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: adminMenu
        }
    ],
    mbpUser: [
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: [
                {
                    name: 'Users',
                    url: '/user-list',
                    icon: 'fa fa-user-circle-o'
                },
                {
                    name: 'Organization',
                    url: '/organization',
                    icon: 'fa fa-sitemap'
                },
                {
                    name: 'Activity History',
                    url: '/activity-history',
                    icon: 'icon-puzzle'
                },
                {
                    name: 'Crop Type',
                    url: '/croptype',
                    icon: 'fa fa-list-ul'
                },
                {
                    name: 'Crop Defaults',
                    url: '/crop-default',
                    icon: 'fa-th-list'
                },
                {
                    name: 'Office Code',
                    url: '/officecode',
                    icon: 'fa fa-briefcase'
                },
                {
                    name: 'News',
                    url: '/news',
                    icon: 'fa fa-newspaper-o'
                },
                {
                    name: 'Discount Code',
                    url: '/discount-code',
                    icon: 'fa fa-tag'
                },
                {
                    name: 'Simulator Users',
                    url: '/simulator-users',
                    icon: 'fa fa-user-o'
                },
                {
                    name: 'Simulator Games',
                    url: '/simulator-games',
                    icon: 'fa fa-gamepad'
                }
            ]
        }
    ],

    admBasic: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: adminMenu
        },
        {
            name: 'Admin MBP/Portfolio',
            url: '/base',
            icon: 'fa fa-address-book',
            children: mbpMenu
        },
        {
            name: 'Alert',
            url: '/alert',
            icon: 'fa fa-exclamation-circle'
        }
    ],
    admPlus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: adminMenu
        },
        {
            name: 'Admin MBP/Portfolio',
            url: '/base',
            icon: 'fa fa-address-book',
            children: mbpMenu
        },
        {
            name: 'Alert',
            url: '/alert',
            icon: 'fa fa-exclamation-circle'
        }
    ],
    admPro: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: adminMenu
        },
        {
            name: 'Admin MBP/Portfolio',
            url: '/base',
            icon: 'fa fa-address-book',
            children: mbpMenu
        },
        {
            name: 'Alert',
            url: '/alert',
            icon: 'fa fa-exclamation-circle'
        }
    ],
    admMbp: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: adminMenu
        },
        {
            name: 'Admin MBP/Portfolio',
            url: '/base',
            icon: 'fa fa-address-book',
            children: mbpMenu
        },
        {
            name: 'Alert',
            url: '/alert',
            icon: 'fa fa-exclamation-circle'
        }
    ],
    admMoMaster: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMoMasterMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: adminMenu
        },
        {
            name: 'Admin MBP/Portfolio',
            url: '/base',
            icon: 'fa fa-address-book',
            children: mbpMenu
        },
        {
            name: 'Alert',
            url: '/alert',
            icon: 'fa fa-exclamation-circle'
        }
    ],
    admMbpMaster: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: adminMenu
        },
        {
            name: 'Admin MBP/Portfolio',
            url: '/base',
            icon: 'fa fa-address-book',
            children: mbpMenu
        },
        {
            name: 'Alert',
            url: '/alert',
            icon: 'fa fa-exclamation-circle'
        }
    ],

    stfBasic: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: staffMenu
        }
    ],
    stfPlus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: staffMenu
        }
    ],
    stfPro: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: staffMenu
        }
    ],
    stfMbp: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: staffMenu
        }
    ],
    stfMoMaster: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMoMasterMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: staffMenu
        }
    ],
    stfMbpMaster: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: staffMenu
        }
    ],

    basicOad: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: endCustomerMenu
        }
    ],
    plusOad: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: endCustomerMenu
        }
    ],
    proOad: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: endCustomerMenu
        }
    ],
    mbpOad: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: endMbpCustomerMenu
        }
    ],

    basicOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        }
    ],
    plusOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        }
    ],
    proOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        }
    ],
    mbpOus: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        }
    ],

    moProbasic: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moLiteAdminMenu
        }
    ],

    supPlan: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceSupMenu
        }
    ],

    moProPlus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moLiteAdminMenu
        }
    ],
    moProProUser: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moLiteAdminMenu
        }
    ],
    moProMbpMaster: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterLiteMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moLiteAdminMenu
        }
    ],

    moPlusProbasic: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moAdminMenu
        }
    ],
    moPlusProPlus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moAdminMenu
        }
    ],
    moPlusProProUser: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moAdminMenu
        }
    ],
    moPlusProMbpMaster: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterLiteMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moAdminMenu
        }
    ],
    moMBP: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moAdminMenu
        }
    ],
    advSponsorMBP: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementSponsorMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advAdminMenu
        }
    ],
    moMBPPro: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advAdminMenu
        }
    ],
    moMBPProStf: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moSponsorMBP: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementSponsorMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moLiteAdminMenu
        }
    ],
    moSponsorMBPStaff: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementSponsorMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moSponsorMBPPlusProLite: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementSponsorMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moAdminMenu
        }
    ],
    advSponsorMBPPlusProLite: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementSponsorMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advAdminMenu
        }
    ],
    moSponsorMBPPlusProLiteMasterStaff: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementSponsorMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moSponsorMBPForMBpUser: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementSponsorMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moLiteAdminMenu
        }
    ],
    moSponsorMBPForMBpUserStaff: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementSponsorMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moSponsorMBPForMBpUserPlusProLite: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moAdminMenu
        }
    ],
    advSponsorMBPForMBpUserPlusProLite: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advAdminMenu
        }
    ],
    moSponsorMBPForMBpUserPlusProLiteStaff: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moStfMBP: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moLitebasic: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moLiteAdminMenu
        }
    ],
    moLitePlus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moLiteAdminMenu
        }
    ],
    moLiteProUser: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moLiteAdminMenu
        }
    ],
    moLiteMbpMaster: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterLiteMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moLiteAdminMenu
        }
    ],

    moPlusLitebasic: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moAdminMenu
        }
    ],
    moPLusLitePlus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moAdminMenu
        }
    ],
    moPlusLiteProUser: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moAdminMenu
        }
    ],
    moPlusLiteMbpMaster: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterLiteMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: moAdminMenu
        }
    ],

    moProbasicOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moProPlusOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moProProUserOus: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moProMbpMasterOus: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterLiteMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],

    moPlusProbasicOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moPlusProPlusOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moPlusProProUserOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moPlusProProUserBasicOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moPlusProMbpMasterOus: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterLiteMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],

    moLitebasicOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moLitePlusOus: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moLiteProUserOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moLiteMbpMasterOus: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterLiteMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],

    moPlusLitebasicOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moPLusLitePlusOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moPlusLiteProUserOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    moPlusLiteMbpMasterOus: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterLiteMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],

    advPlusProbasic: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advAdminMenu
        }
    ],
    advPlusProPlus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advAdminMenu
        }
    ],
    advPlusProProUser: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advAdminMenu
        }
    ],
    advPlusProMbpMaster: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterLiteMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advAdminMenu
        }
    ],

    advPlusLitebasic: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advAdminMenu
        }
    ],
    advPLusLitePlus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advAdminMenu
        }
    ],
    advPlusLiteProUser: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advAdminMenu
        }
    ],
    advPlusLiteMbpMaster: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterLiteMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advAdminMenu
        }
    ],

    advPlusProbasicOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    advPlusProPlusOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    advPlusProProUserOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    advPlusProMbpMasterOus: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterLiteMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenu
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],

    advPlusLitebasicOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementBasicMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    advPLusLitePlusOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceAthenaLockedMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    advPlusLiteProUserOus: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Dashboard',
            url: '/pen-dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],
    advPlusLiteMbpMasterOus: [
        {
            name: 'Performance',
            url: '/base',
            icon: 'fa fa-line-chart',
            children: PerformanceMbpMasterLiteMenu
        },
        {
            name: 'Management',
            url: '/base',
            icon: 'fa fa-link',
            children: managementMbpMenu
        },
        {
            name: 'Resources',
            url: '/base',
            icon: 'fa fa-book',
            children: resourceMenuExceptEduStratagy
        },
        {
            name: 'Admin',
            url: '/base',
            icon: 'fa fa-cogs',
            children: advStfMenu
        }
    ],

    aysalesStaff: []
};