/** HttpRequestOption model is used for API service input*/
import { NameValueData } from './name.value.model';

export class HttpRequestOption {
    constructor() {
        this.headers = [];
    }

    url = '';
    contentType = 'application/json';
    mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    headers: Array<NameValueData>;
    postData: any;
    getData: any;
    fileName: string = 'File-' + new Date().getTime() + '.xlsx';
    isOpenInNewTab = false;
    requestOption: any; // can be set when need to pass additional requestOption other than headers
}
