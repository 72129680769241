/** ApiUrlConfig is used to listed all the API relative urls*/
export class ApiUrlConfig {
    /** Auth module api relative url  */
    public static Auth = class {
        public static consumerSecret = 'oauth/consumersecret';
        public static RequestToken = 'oauth/requestToken';
        public static LoginUrl = 'oauth/authorize';
        public static AccessToken = 'oauth/accessToken';
        public static TermsOfUse = 'termsOfUse/fetchTermsOfUse';
        public static SaveTermsOfUse = 'termsOfUse/saveStatus';
        public static checkShowLastMonthPopup = 'user/checkShowLastMonthPopup';
        public static doNotShowLastMonthMessage = 'user/doNotShowLastMonthMessage';
    };

    /** Portfolio MBP  */
    public static PortfolioMbp = class {
        public static getAllPortfolio = 'portfolioMaster/getAllPortfolio';
        public static deletePortfolio = 'portfolioMaster/deletePortfolio';
        public static getCroTypes = 'cropType/getAllCropType';
        public static getPortfolioById = 'portfolioMaster/getPortfolioById';
        public static getPortfolioChildDataById = 'portfolioMaster/getPortfolioChildById';
        public static getAllLevels = 'portfolioMaster/getAllLevel';
        public static getMbp = 'portfolioMaster/getMbp';
        public static createPortfolio = 'portfolioMaster/create';
        public static getActiveYears = 'common/getActiveYears';
        public static getOpenContractMonth = 'transaction/getOpenMonths';
        public static allPortfolioWithCustomer = 'portfolioMaster/getAllPortfolioWithCustomer';
        public static updatePortfolio = 'portfolioMaster/updatePortfolio';
        public static approveRejectPortfolio = 'portfolioMaster/approveRejectPortfolio';
        public static downloadReport = 'portfolioMaster/downloadReport';
    };

    /** AgyieldMbp module api relative url  */
    public static AgyieldMbp = class {
        public static getPerformanceChartDtls = 'poolAccounts/getPerformanceChartDtls';
        public static getPerformanceChartFacet = 'poolAccounts/getFacetDetails';
        public static fetch = 'forecastMatrix/fetch';
    }

    /** Dashboard module api relative url  */
    public static Dashboard = class {
        public static saveDashboardData = 'dashboard/saveDashboardLayout';
        public static getDashboardData = 'dashboard/getDashboardLayout';
        public static saveSelectedYear = 'user/saveUserSelectedYear';
        public static getAccountSummary = 'dashboard/getAccountSumm';
        public static getRiskScore = 'benchmarking/getRiskScore';
        public static getAcrageChartData = 'fieldCrop/getCropListFromSolr';
        public static getCurrentProfitability = 'dashboard/getCurrentProfitability';
        public static getPoolAccountsDashboardDetails = 'poolAccounts/getDashboardDetails';
        public static getMPDropdown = 'poolAccounts/getFacetDetails';
        public static getNews = 'news/fetchNews';
        public static getPerformanceChart = 'dashboard/getPerformanceChartDtls';
        public static getWeatherImage = 'dashboard/getWeatherImage';
        public static getPoolSummaryDetails = 'poolAccounts/getPoolSummaryDetails';
        public static getPCStartEndDate = 'dashboard/getPCStartEndDate';
    };
    /** Dashboard module api relative url  */
    public static PenDashboard = class {
        public static getHeaderDetail = 'pen-dashboard/getHeaderDetail';
        public static getAccountSummary = 'penAccSummary/getAccountSumm';
        public static getCurrentProfitability = 'pen-dashboard/getCurrentProfitability';
    };

    /** Portfolio Questionnaire  */
    public static PortfolioQue = class {
        public static queList = 'portfolioQuestionnaire/getPortfolioQuesList';
        public static deleteQue = 'portfolioQuestionnaire/delete';
        public static uploadQue = 'portfolioQuestionnaire/create';
    };
    /** Discount Code module api relative url  */
    public static DiscountCode = class {
        public static FetchDiscountCodeList = 'discountCode/fetchDiscountCode';
        public static GetDiscountCodeDetail = 'discountCode/getDiscountDetail';
        public static GetPlans = 'user/getPlans';
        public static AddDiscountCode = 'discountCode/create';
        public static EditDiscountCode = 'discountCode/update';
        public static DeleteDiscountCode = 'discountCode/delete';
        public static GetDiscountCodeReport = 'discountCode/downloadReport';
        public static DiscountCodeReportById = 'discountCode/codeReportById';
        public static GetDiscountCodeReportById = 'discountCode/loadReportById';

        public static ValidateDiscountCode = 'discountCode/validateDiscountCode';
        public static ValidateDealerCode = 'discountCode/validateDealerCode';

        public static PaypalPayment = 'user/processing';
        public static checkCouponDealerCode = 'discountCode/checkCouponDealerCode';
    };

    /** Discount Code module api relative url  */
    public static Payment = class {
        public static CeatePortfolioPayment = 'portfolioPayment/create';
    };

    /** Global Strategy module api relative url  */
    public static GlobalStrategy = class {
        public static FetchGlobalStrategyDetail = 'globalStrategy/fetchGlobalStrategyDetail';
        public static GetGlobalStrategyList = 'globalStrategy/getAllGlobalStrategy';
        public static CreateGlobalStrategy = 'globalStrategy/create';
        public static DeleteGlobalStrategy = 'globalStrategy/deleteGlobalStrategy';
        public static GetGlobalStrategyTransaction = 'globalStrategy/getGlobalStrategyTransactions';
        public static DeleteGlobalStrategyTransaction = 'globalStrategy/deleteGlobalStrategyTransaction';
        public static CreateGlobalStrategyTransaction = 'globalStrategy/createGlobalStrategyTransaction';
        public static PublishGlobalStrategy = 'globalStrategy/publishGlobalStrategy';
        public static CreateGlobalStrategyInsurance = 'globalStrategy/createGlobalStrategyInsurance';
        public static getGlobalStrategyTransactionOutlook = 'globalStrategy/getGSTransactions';
        public static MoveGSToWhatif = 'globalStrategy/moveToWhatif';
        public static CreateGlobalStrategySpread = 'globalStrategy/createGlobalStrategySpread';
    };

    /** Field module api relative url  */
    public static Field = class {
        public static ApiFieldList = 'field/getFieldListFrmSolr';
        public static ApiDeleteField = 'field/deleteField';
        public static ApiProductionList = 'field/getAPHList';
        public static ApiUpdateAPH = 'field/updateAPH';
        public static ApiGetEntityList = 'field/getEntityList';
        public static ApiGetCountyList = 'field/getCountyList';
        public static ApiGetFieldDetails = 'field/getFieldById';
        public static ApiGetFieldSave = 'field/create';
        public static ApiGetFieldEdit = 'field/Update';
    };

     /** Field module api relative url  */
     public static Customer = class {
        public static CustomerList = 'user/getCustomerListFromSolr';
        public static DeleteCustomer = 'user/deleteCustomer';
        public static FetchAdminUser = 'user/orgAdminUsers';
        public static AssignUser = 'user/assignUser';
        public static InviteUser = 'user/inviteSo';
        public static AddCustomer = 'user/addCustomer';
        public static EditCustomer = 'user/EditCustomer';
    };


    /** Advisor module api relative url  */
    public static Advisor = class {
        public static AdvisorList = 'moAdvisor/getList';
        public static CreateAdvisor = 'moAdvisor/create';
        public static DeleteAdvisor = 'moAdvisor/delete';
        public static EditCustomer = 'user/EditCustomer';
    };

    public static Simulator = class {
        public static SimulatorUsersList = 'user/getSimulatorUsersFromSolr';
        public static SetStatus = 'user/setStatus';
        public static SendGameAdminApproval = 'simulator/game/sendGameAdminApproval';
        public static UpdateUser = 'user/updateUser';
        public static simulatorGameList = 'user/getGameListFromSolr';
        public static GetGameDetail = 'simulator/game/getGame';
        public static ChangeGameStatus = 'simulator/game/changeGameStatus';
        
        
    };
    


    /** User module api relative url  */
    public static User = class {
        public static ApiUserList = 'user/getUserListFromSolr';
        public static ApiDeleteUser = 'user/setStatus';
        public static ApiInactiveUser = 'user/inactive';        
        public static ApiCheckPoolStatus = 'user/checkPoolStatus';
        public static RevokeAccessFromMo = 'user/revokeAccessFromMo';
        public static ApiResendInvitationMail = 'user/resendInvitationMail';
        public static ApiBasicChequeVerify = 'user/basicChequeVerify';
        public static ApiCheckTestingAccount = 'user/makeTestUser';
        public static ApiReActivateUser = 'user/makeUserActive';
        public static ApiSuspendUser = 'user/suspendUser';
        public static ApiSetElevator = 'user/setElevator';

        public static ApiOrganizationList = 'organization/getOrganizationList';
        public static ApiRoleList = 'user/getRoles';
        public static ApiMoAdvisorList = 'moAdvisor/getOrganizationList';
        public static ApiGetStates = 'oauth/getState';
        public static ApiGetPlans = 'user/getPlans';
        public static ApiCreateUser = 'user/create';
        public static ApiUpdateUser = 'user/updateUser';
        public static setMonthlyStatement = 'user/setMonthly';        
        public static changePassword = 'user/changePassword';
        public static ApiGetCountyList = 'field/getCountyList';
        public static ApiTradingAccountList = 'userTradingAccount/tradingList';
        public static ApiSaveTradingAccount = 'userTradingAccount/create';
        public static ApiFcmSourceList = 'userTradingAccount/fcm';
        public static ApiOfficeList = '/userTradingAccount/officeByFcm';
        public static ApiFetchEntity = '/userTradingAccount/fetch';
        public static ApiFetchTradingAccountDetails = '/userTradingAccount/tradeById';
        public static ApiDeleteTradingAccount = '/userTradingAccount/delete';
        public static ApiFetchUser = 'user/fetchUser';
        public static ApiGetImageUrl = 'user/imageUrl';
        public static ApiFetchStaffUsers = 'user/fetchStaffUsers';
        public static ApiAssignStaffUsers = 'user/assignStaffUser';
        public static ApiSaveCgAccount = 'userCg/create';
        public static ApiCgSourceList = 'cg/cgSourceList';
        public static ApiFetchCgEntity = '/userCg/fetch';
        public static ApiCgAccountList = 'userCg/userCgList';
        public static ApiFetchUserCgAccountDetails = '/userCg/tradeById';
        public static ApiDeleteUserCgAccount = '/userCg/delete';
        public static ApiGetSignUpPlans = 'user/getSignUpPlans';
        public static ApigetUserFarmAcers = 'user/getUserFarmAcers';
        public static ApigetgenerateJWTToken = 'user/getJWTToken';
        public static ApigetCheckAgYieldUserMoreThenOneEntityOrField = 'user/checkAgYieldUserMoreThenOneEntityOrField';
        public static ApiEnableDisableLivestock = 'user/enableDisableLivestock';
    };

    /** Field module api relative url  */
    public static Entity = class {
        public static EntityList = 'entity/getEntityListFrmSolr';
        public static DeleteEntity = 'entity/deleteEntity';
        public static EntityFieldList = 'field/getFieldListFrmSolr';
        public static AddEntity = 'entity/create';
        public static EditEntity = 'entity/update';
        public static EntityPenList = 'entity/getpensummarybyentity';
    };

    /** Office Code module api relative url  */
    public static OfficeCode = class {
        public static ApiOfficeCodeList = '/office/officeCodeList';
        public static ApiFcmSourceList = '/userTradingAccount/fcm';
        public static ApiCreate = '/office/create';
    };

    /** CropProphet module api relative url  */
    public static CropProphet = class {
        public static getIntroduction = '/cropProphet/getIntroductionvideoUrl';
        public static getYieldForecastCropYear = '/cropProphet/getYieldForecastCropYear';
        public static getYieldForecastVideo = '/cropProphet/getYieldForecastVideo';
        public static sendMailToAyAdmin = '/cropProphet/sendMailToAyAdmin';
        public static setCropProphet = '/user/setCropProphet';
        public static getCropProphetAuth = '/cropProphet/getCropProphetAuth';
        public static acceptTermsAndConditions = '/cropProphet/acceptTermsAndConditions';
    };

    /** Office Code module api relative url  */
    public static AccountSummary = class {
        public static AccountSummaryList = '/dashboard/getAccountSumm';
        public static PoolAccountSummaryList = '/poolAccounts/getPoolAccountSummary';
        public static Report = '/dashboard/loadReport';
        public static RiskScore = '/benchmarking/getRiskScore';
        public static setMoMaster = '/user/setMoMaster';
    };
    /** Header module api relative url  */
    public static Header = class {
        public static userInfo = 'user/fetchUser';
        public static activeYears = 'common/getActiveYears';
        public static activeCrops = 'cropInsurance/getCrops';
        public static searchUser = 'user/getAllOrg';
        public static switchCustomer = 'user/switchToCustomer';
        public static setUser = 'user/saveUserSelectedYear';
        public static notification = 'userNotification/getNotifications';
        public static logout = 'oauth/logout';
        public static imageUrl = 'user/imageUrlWeb';
        public static removeNotification = 'userNotification/dismissNotification';
        public static getMoCropCount = 'user/getMoCropCount';
        public static getPoolCount = 'cropInsurance/getPoolCount';
        public static setUserSelectedStockType = 'user/saveUserSelectedStockType';
        public static activeLivestockYears = 'common/getLivestockActiveYears';
    };

    /** Crop module api relative url  */
    public static Crop = class {
        public static CropList = 'fieldCrop/getCropListFromSolr';
        public static DeleteCrop = 'fieldCrop/deleteCrop';
        public static CropViewFieldList = 'fieldCrop/getFieldsByCrop';

        public static cropList = 'poolAccounts/getPoolAccountList';
        public static FetchCropTypes = 'fieldCrop/getCropTypeList';
        public static FetchActiveYears = 'common/getActiveYears';
        public static fetchFieldList = 'fieldCrop/getFieldList';
        public static getProductionUnits = 'common/getProductionUnits';
        public static getCropDefDetails = 'fieldCrop/getCropDefDetails';
        public static getFutureContractMonth = 'fieldCrop/getFutureContractMonth';
        public static CreateCrop = 'fieldCrop/create';
        public static FetchCropDetails = 'fieldCrop/getCropById';
        public static deleteFieldCrop = 'fieldCrop/deleteFieldCrop';
        public static EditCrop = 'fieldCrop/update';
        public static CopList = 'fieldCrop/getFieldCropCost';
        public static MarketData = 'fieldCrop/getMarketData';
        public static getOrgById = 'fieldCrop/getOrgById';
        // Crop prophet yield
        public static getCropProphetYield = 'fieldCrop/getCropProphetYield';
        public static getHistoricalData = 'yieldForecastHistory/getHistoricalData';
        public static getUOMValidation = 'fieldCrop/getUOMValidation';
        public static verifySeededAcresWithTotalFarmAcres = 'fieldCrop/verifySeededAcresWithTotalFarmAcres';
        public static validateCrossUmoAndFlexRentExist = 'fieldCrop/validateCrossUmoAndFlexRentExist';
    };

    /** Pen module api relative url  */
    public static Pen = class {
        public static FetchLiveStockTypes = 'livestockType/getLivestockTypeList';
        public static CreatePen = 'pen/createPen';
        public static EditPen = 'pen/updatePen';
        public static PenList = 'pen/getPenList';
        public static DeletePen = 'pen/deletePen';
        public static FetchPenDetails = 'pen/getPenById';
        public static PenViewEntityList = 'pen/getEntityByPen';
        public static getPenExistByOrganizationId = 'pen/getPenExistByOrganizationId';
        public static getFutureContractFO = 'pen/getFutureContractMonth';
    };
    public static Mbp = class {
        public static mbpList = 'poolAccounts/getPoolAccountList';
        public static FetchCropTypes = 'cropType/getAllCropType';
        public static FetchActiveYears = 'common/getActiveYears';
        public static FetchOpenMonths = 'transaction/getOpenMonths';
        public static FetchOptionMonths = 'transaction/getOptionMonthsForMbp';
        public static FetchExpiryDates = 'transaction/getExpireDates';
        public static CreateMbp = 'poolAccounts/create';
        public static FetchMbpDetails = 'poolAccounts/getPoolAccountDetails';
        public static EditMbp = 'poolAccounts/update';
    };
    public static TransactionRollOver = class {
        public static TransactionRollOverList = 'transactionRollOver/getTransactionRollOverList';
        public static createRollOver = 'transactionRollOver/createRollOver';
        public static deleteRollOver = 'transactionRollOver/deleteRollOver';
        public static parentRemainingQuantity = 'transactionRollOver/parentRemainingQuantity';
        public static getMaxRollOverDate = 'transactionRollOver/getMaxRollOverDate';
    };

    public static PenTransaction = class {
        public static TransactionList = 'penTransaction/getTransactionList';
        public static AccumulationDetails = 'penTransaction/fetchAccumulationDtls';
        public static BuyerList = 'penBuyer/getBuyerListDetail';
        public static UpdateAccumulation = 'penTransaction/updateAccumlationPrice';
        public static DeleteTransaction = 'penTransaction/delete';
        public static getNativeUom = 'penTransaction/getNativeUom';
        public static getSoldQnty = 'penTransaction/getSoldQnty';

       public static  getFutureContractFO = 'penTransaction/getFutureContractMonth';

        // HTA
        public static getPens = 'penInsurance/getPens';
        public static getPenWithNativeUOM = 'penTransaction/getPenWithNativeUOM';
        public static getBuyer = 'penTransaction/buyer';
        public static create = 'penTransaction/create';
        public static update = 'penTransaction/updatePost';
        public static fetch = 'penTransaction/fetch';

        // Basis
        public static getFutureContract = 'fieldCrop/getFutureContractMonth';

        // Insurance
        public static getInsuranceType = 'penInsurance/getInsuraceType';

        // firmTransaction Price base on min price
        public static customOrfirmTransactionPrice = 'penTransaction/finalFirmOfferPrice';

        // Get number of heads sum
        public static getNoOfHeads = 'penTransaction/getNoOfHeads';
    };

    public static Transaction = class {
        public static TransactionList = 'transaction/getTransactionListFromSolr';
        public static AccumulationDetails = 'transaction/fetchAccumulationDtls';
        public static BuyerList = 'buyer/getBuyerListDetail';
        public static UpdateAccumulation = 'transaction/updateAccumlationPrice';
        public static DeleteTransaction = 'transaction/delete';
        public static getNativeUom = 'fieldCrop/getNativeUom';

        // HTA
        public static getCrops = 'cropInsurance/getCrops';
        public static getBuyer = 'transaction/buyer';
        public static create = 'transaction/create';
        public static update = 'transaction/updatePost';
        public static fetch = 'transaction/fetch';

        // Basis
        public static getFutureContract = 'fieldCrop/getFutureContractMonth';

        // Insurance
        public static getInsuranceType = 'cropInsurance/getInsuraceType';

        // firmTransaction Price base on min price
        public static customOrfirmTransactionPrice = 'transaction/finalFirmOfferPrice';
    };

    public static WhatifTransaction = class {
        public static SaveTrans = 'whatif/saveTran';
        public static UpdateTrans = 'whatif/updateTrans';
        public static WhatifList = 'whatif/list';
        public static WhatifDelete = 'whatif/delete';
        public static WhatifDeleteAll = 'whatif/deleteAll';
        public static WhatifEdit = 'whatif/edit';
        public static WhatifSpreadDetail = 'whatif/getSpreadDetails';
        public static SaveWhatifSpread = 'whatif/saveWhatifSpread';
        public static UpdateWhatifSpread = 'whatif/updateWhatIfSpread';
        public static GsWhatifEdit = 'whatif/fetchGsEditdetail';

        public static WhatifSaveInsurance = 'whatif/saveIns';
        public static WhatifChildInsurance = 'cropInsurance/fetchChildInsuranceType';
        public static WhatifEditInsurance = 'whatif/updateIns';

        public static strategyList = 'whatif/listStrategy';
        public static moveToWhatIf = 'whatif/moveStrategy';
        public static deleteStrategy = 'whatif/deleteStrategy';
        public static saveStrategyName = 'whatif/saveStrategyName';

        public static getOpenFoTransactions = 'whatif/getOpenFoTransactions';
        public static liquidateFo = 'whatif/liquidateFoTransaction';
    };

    public static activityHistory = class {
        public static FetchActivityHistory = 'activity/index';
        public static FetchActivityDetail = 'activity/view';
    };

    public static indemnity = class {
        public static getCropList = 'transaction/getCropInsByCrop';
        public static create = 'transaction/saveIdemnity';
        public static editDetail = 'transaction/fetch/';
    };
    public static PenIndemnity = class {
        public static getCropList = 'penTransaction/getCropInsByCrop';
        public static create = 'penTransaction/saveIdemnity';
        public static editDetail = 'penTransaction/fetch/';
    };
    public static PenAccumulator = class {
        
        public static getCrops = 'penTransaction/getCropsWithNativeUOM';        
        public static getBuyer = 'penTransaction/buyer';
        public static getEntityList = 'penTransaction/entityByCropId';        
        //public static getContractMonth = 'fieldCrop/getFutureContractMonth';
        public static getSettlementPrice = 'penTransaction/getSettlementPrice';
        public static getSettlementFuturesPrice = 'penTransaction/getSettlementFuturesPrice';
        public static getActualSettlementPrice = 'penTransaction/getActualSettlementPrice';
        public static getCropUom = 'penTransaction/convertedPriceById';
        public static getAccumulation = 'penTransaction/fetchAccumulationDtls';
        public static getTransactionDetail = 'penTransaction/fetch';
    };
    public static accumulator = class {
        public static getCrops = 'transaction/getCropsWithNativeUOM';
        public static getBuyer = 'transaction/buyer';
        public static getEntityList = 'transaction/entityByCropId';
        public static getContractMonth = 'fieldCrop/getFutureContractMonth';
        public static getSettlementPrice = 'transaction/getSettlementPrice';
        public static getSettlementFuturesPrice = 'transaction/getSettlementFuturesPrice';
        public static getActualSettlementPrice = 'transaction/getActualSettlementPrice';
        public static getCropUom = 'transaction/convertedPriceById';
        public static getAccumulation = 'transaction/fetchAccumulationDtls';
        public static getTransactionDetail = 'transaction/fetch';
    };

    public static otherRevenue = class {
        public static create = 'transaction/saveOtherRevenue';
    };

    public static PenOtherRevenue = class {
        public static create = 'penTransaction/saveOtherRevenue';
    };

    public static Organization = class {
        public static OrganizationList = 'organization/getOrgListFromSolr';
        public static SetBenchmark = 'organization/setBenchmark';
        public static SetMoMaster = 'organization/setMOMasterAccount';
        public static CheckOrganization = 'organization/checkForAdmin';
        public static DeleteOrganization = 'organization/deleteOrganization';
        public static FetchOrganizationDetails = 'organization/fetchOrganization';
        public static EditOrganization = 'organization/update';
        public static FetchStates = 'oauth/getState';
        public static SetMBPEnrollment = 'organization/setMBPEnrollment';
        public static SetMBPSponsor = 'organization/setMBPSponsor';
    };

    public static HedgesSnapshot = class {
        public static HedgesSnapshotChartDetail = 'dashboard/getHedgesChartDtls';
    };

    public static CashTransaction = class {
        public static getBtParticipant = 'cropInsurance/getBTPoolParticipants';
    };

    public static CropType = class {
        public static CropTypeList = 'fieldCrop/getCropTypeList';
        public static DeleteCropType = 'cropType/removeCropType';
        public static SaveCropType = 'cropType/create';
        public static FetchCropTypeDetails = 'cropType/getCropTypeDetail';
        public static GetAllCropType = 'cropType/getAllCropType';
        public static fetchHarvestMonths = 'transaction/getContractMonthsForCroptype';
    };

    public static PenInsurance = class {
        public static InsuranceList = 'penInsurance/index';
        public static DeleteInsurance = 'penInsurance/delete';
        public static GetPens = 'penInsurance/getLSType';        
        public static GetPenEntity = 'penInsurance/getPenEntity';
        public static GetInsuranceType = 'penInsurance/getInsuraceType';
        public static PenInsuranceAdd = 'penInsurance/add';
        public static PenInsuranceUpdate = 'penInsurance/update';
        public static InsuranceGetDetails = 'penInsurance/edit';
        //public static HarvestUnlock = 'penInsurance/harvestUnlock';
        //public static GetCompanionPolicyList = 'penInsurance/fetchChildInsuranceType';
    };
    public static Insurance = class {
        public static InsuranceList = 'cropInsurance/index';
        public static DeleteInsurance = 'cropInsurance/delete';
        public static GetCrops = 'cropInsurance/getCrops';
        public static GetCropFields = 'cropInsurance/getCropFields';
        public static GetInsuranceType = 'cropInsurance/getInsuraceType';
        public static InsuranceAdd = 'cropInsurance/add';
        public static InsuranceUpdate = 'cropInsurance/update';
        public static InsuranceGetDetails = 'cropInsurance/edit';
        public static HarvestUnlock = 'cropInsurance/harvestUnlock';
        public static GetCompanionPolicyList = 'cropInsurance/fetchChildInsuranceType';
    };

    public static cropDefaultInsurance = class {
        public static getCropDefaultInsurance = 'cropDefaultInsurance/getCropDefIns';
        public static getCropNames = 'cropDefaultInsurance/getCropNames';
        public static getCostOfProdBreakdown = 'cropDefaultInsurance/getCostOfProdBreakdown';
        public static cropDefaultInsuranceCreate = 'cropDefaultInsurance/create';
        public static cropDefaultInsuranceUpdate = 'cropDefaultInsurance/update';
        public static cropDefaultInsuranceDelete = 'cropDefaultInsurance/delete';
    };

    public static TransactionFuture = class {
        public static FetchCropTypes = 'cropInsurance/getCropType';
        public static GetCropTypeList = 'fieldCrop/getCropTypeList';
        public static GetSettlementPrice = 'transaction/getSettlementPrice';
        public static GetSubCrop = 'transaction/subCrop';
        public static EntityByCropId = 'transaction/entityByCropId';
        public static FetchOpenMonths = 'transaction/getOpenMonths';
        public static ApiFcmSourceList = 'userTradingAccount/fcm';
        public static GetTradingAccount = 'userTradingAccount/tradingListByFcm';
        public static CreateFuture = 'transaction/createFuture';
        public static FetchTransactionDetails = 'transaction/fetch';
        public static UpdateFuture = 'transaction/updateFuture';
        public static CropsByEntityId = 'transaction/cropsByEntityId';
        public static ApiCgSourceList = 'cg/cgSourceList';
        public static ApiCustomerCodeList = 'userCg/customerCodeListByCg';
    };

    /** AG-4905  Pen Transaction Future Contract */
    public static PenTransactionFuture = class {
        public static FetchLiveStockTypes = 'livestockType/getLivestockTypeList';
        public static FetchCropTypes = 'cropInsurance/getCropType';
        public static GetCropTypeList = 'fieldCrop/getCropTypeList';
        public static GetSettlementPrice = 'penTransaction/getSettlementPrice';
        public static GetSubCrop = 'penTransaction/subCrop';
        public static entityByPenId = 'penTransaction/entityByPenId';
        public static FetchOpenMonths = 'penTransaction/getOpenMonths';
        public static ApiFcmSourceList = 'userTradingAccount/penfcm';
        public static GetTradingAccount = 'userTradingAccount/tradingListByFcm';
        public static CreateFuture = 'penTransactionFuture/createFuture';
        public static FetchTransactionDetails = 'penTransactionFuture/fetch';
        public static UpdateFuture = 'penTransactionFuture/updateFuture';
        public static CropsByEntityId = 'penTransaction/cropsByEntityId';
        public static ApiCgSourceList = 'cg/cgSourceList';
        public static ApiCustomerCodeList = 'userCg/customerCodeListByCg';
    };


    public static PenTransactionOption = class {
        public static FetchLiveStockTypes = 'livestockType/getLivestockTypeList';
        public static FetchCropTypes = 'cropInsurance/getCropType';
        public static GetCropTypeList = 'fieldCrop/getCropTypeList';
        public static GetSettlementPrice = 'penTransaction/getSettlementPrice';
        public static GetPens = 'penTransaction/getPens';
        public static EntityByPenId = 'penTransaction/entityByPenId';
        public static FetchOpenMonths = 'penTransaction/getOpenOptionMonths';
        public static FetchExpireDate = 'penTransaction/getExpireDates';
        public static FetchStrikePrice = 'penTransaction/getStrike';
        public static GetTradingAccount = 'userTradingAccount/tradingListByFcm';
        public static entityByPenId = 'penTransaction/entityByPenId';
        public static ApiFcmSourceList = 'userTradingAccount/penfcm';
        public static CreateOption = 'penTransactionOption/createOption';
        public static FetchTransactionDetails = 'penTransactionOption/fetch';
        public static UpdateOption = 'penTransactionOption/updateOption';
        //public static CropsByEntityId = 'penTransaction/cropsByEntityId';
        public static EntityByCropId = 'penTransaction/entityByCropId';
                                              
        public static FetchEhedgerMBPList = 'backtestingPool/getEhedgerMBPList';
        public static ApiCgSourceList = 'cg/cgSourceList';
        public static ApiCustomerCodeList = 'userCg/customerCodeListByCg';
    };




    public static TransactionOption = class {
        public static FetchCropTypes = 'cropInsurance/getCropType';
        public static GetCropTypeList = 'fieldCrop/getCropTypeList';
        public static GetSettlementPrice = 'transaction/getSettlementPrice';
        public static GetSubCrop = 'transaction/subCrop';
        public static EntityByCropId = 'transaction/entityByCropId';
        public static FetchOpenMonths = 'transaction/getOpenOptionMonths';
        public static FetchExpireDate = 'transaction/getExpireDates';
        public static FetchStrikePrice = 'transaction/getStrike';
        public static ApiFcmSourceList = 'userTradingAccount/fcm';
        public static GetTradingAccount = 'userTradingAccount/tradingListByFcm';
        public static CreateOption = 'transaction/createOption';
        public static FetchTransactionDetails = 'transaction/fetch';
        public static UpdateOption = 'transaction/updateOption';
        public static CropsByEntityId = 'transaction/cropsByEntityId';
        public static FetchEhedgerMBPList = 'backtestingPool/getEhedgerMBPList';
        public static ApiCgSourceList = 'cg/cgSourceList';
        public static ApiCustomerCodeList = 'userCg/customerCodeListByCg';
    };

    public static Expense = class {
        public static GetCrops = 'cropInsurance/getCrops';
        public static CropLevelExpenseList = 'fieldCrop/getCropListFromSolr';
        public static FieldLevelExpenseList = 'field/getFieldListFrmSolr';
        public static GetFieldCategory = 'fieldCrop/getFieldCategory';
        public static GetCropExpense = 'fieldCrop/getCropExpense';
        public static GetExpMgmtType = 'fieldCrop/getExpMgmtType';
        public static GetExpenseReport = 'fieldCrop/getExpenseReport';
        public static getCropInfo = 'fieldCrop/getCropInfo';
        public static getFieldCropDef = 'fieldCrop/getFieldCropDef';
        public static getCostOfProdBreakdown = 'fieldCrop/getCostOfProdBreakdown';
        public static getCropsWithChildCrop = 'cropInsurance/getCropsWithChildCrop';
        public static getCostOfProdBudgetedBreakdown = 'fieldCrop/getCostOfProdBudgetedBreakdown';
        public static saveCopSetting = 'fieldCrop/saveCopSetting';
        public static saveCOPCategory = 'fieldCrop/saveCOPCategory';
        public static deleteCOPCategory = 'fieldCrop/deleteCOPCategory';
        public static updateExpMgmtType = 'fieldCrop/updateExpMgmtType';
        public static saveExpense = 'fieldCrop/saveExpense';
        public static deleteFlexRent = 'fieldCrop/deleteFlexRent';
        public static getFieldsNameListByCrop = 'fieldCrop/getFieldsNameListByCrop';
        public static getActiveYearsExpMgmtTypeWise = 'common/getActiveYearsExpMgmtTypeWise';
    };

    public static PenExpense = class {
        public static getActiveYears = 'common/getActiveYears';
        public static getPenInfo = 'pen/getPenInfo';
        public static GetPenExpense = 'pen/getPenExpense';
        public static GetExpMgmtType = 'pen/getExpMgmtType';
        public static PenList = 'pen/getPenList';
        public static getPensWithChildPen = 'pen/getPensWithChildPen';
        public static getCostOfProdBreakdown = 'pen/getCostOfProdBreakdown';
        public static getCostOfProdBudgetedBreakdown = 'pen/getCostOfProdBudgetedBreakdown';
        public static saveCopSetting = 'pen/saveCopSetting'; 
        public static saveCOPCategory = 'pen/saveCOPCategory';
        public static deleteCOPCategory = 'pen/deleteCOPCategory';
        public static GetPenCategory = 'pen/getPenCategory';
    }

    public static News = class {
        public static getNewsList = 'news/fetchNews';
        public static getNewsDetail = 'news/fetchNewsByNewsId';
        public static save = 'news/create';
        public static update = 'news/update';
        public static approveReject = 'news/status';
        public static delete = 'news/deleteNews';
    };

    public static Common = class {
        public static getPlanList = 'plan/';
        public static getEntityList = 'transaction/entityByCropId';
        public static customerListByCg = 'userCg/customerListByCg';
    };

    /** Portfolio Questionnaire  */
    public static PortfolioRegistration = class {
        public static stateList = 'oauth/getState';
        public static representativeList = 'user/fetchSalesStaffUsers';
        public static MOList = 'organization/fetchPortfolioOrg';
        public static getCaptchaCode = 'portfolioMaster/getCaptchaCode';
        public static verifyCaptchaCode = 'portfolioMaster/verifyCaptchaCode';
        public static MBPSponsorList = 'organization/fetchMbpSponsorList';
        public static levelsOfPortfolio = 'portfolioMaster/getLevels';
        public static archChartDate = 'portfolioMaster/getAcreChartDate';
        public static savePortfolio = 'portfolioMaster/saveUserPortfolio';
        public static filteredPortfolioDetail = 'portfolioMaster/getFilteredPortfolioDetail';
        public static portfolioQueList = 'portfolioQuestionnaire/getPortfolioQuesList';
        public static custSelectedAns = 'portfolioCustQuesAns/getCustSelectedAns';
        public static getOtp = 'user/getOtp';
        public static verifyOtp = 'user/verifyOtp';
        public static saveMBPUser = 'user/create';
        public static savePortfolioUser = 'user/createPortfolioUser';
        public static activeCropDetail = 'portfolioMaster/getActiveCropDetail';
        public static activePortfolioDetail = 'portfolioMaster/getActivePortfolioDetail';
        public static basePrice = 'portfolioMaster/getBasePrice';
        public static entity = 'portfolioMaster/getEntity';
        public static guarantee = 'portfolioMaster/getGuarantee';

        public static savePortfolioCustQuesAns = 'portfolioCustQuesAns/savePortfolioCustQuesAns';
        public static getCustCropDetail = 'portfolioMaster/getCustCropDetail';
        public static getActivePortfolio = 'portfolioMaster/getActivePortfolioDetail';
        public static getCustQuestionnaireScore = 'portfolioCustQuesAns/getCustQuestionnaireScore';
        public static getActivePortfolioData = 'portfolioMaster/getActivePortfolioData';
        public static getCustPortfolioDetail = 'portfolioMaster/getCustPortfolioDetail';
        public static savePortfolioCrop = 'portfolioMaster/savePortfolioCrop';

        public static getSponsorList = 'organization/fetchMbpSponsorList';
        public static getSponsorEditData = 'portfolioMaster/fetchSponsorData';
        public static getPlanAmount = 'user/getDurationPlanAmount';
        public static validateRepresentative = 'user/validateRepresentative';
        public static cropAndSubCropList = 'cropType/getSubCropWithParent';
    };

    // API Urls for profitability outlook page
    public static Outlook = class {
        public static fetch = 'forecastMatrix/fetch';
        public static resetSettings = 'forecastMatrix/resetSettings';
        public static checkWhatIfName = 'forecastMatrix/checkWhatIfName';
        public static getTabularComparisonData = 'forecastMatrix/getTabularComparisonData';
        public static getReportPreview = 'forecastMatrix/reportPreview';
        public static publishStrategy = 'whatif/publishStrategy';
        public static userDetail = 'user/userByOrg';
        public static comparisonData = 'forecastMatrix/getTabularComparisonData';
        public static getOutlookCropImages = 'outlookTimeline/getOutlookCropImages';
        public static getOutlookCropData = 'outlookTimeline/getOutlookCropData';
        public static getsAvailableDates = 'outlookTimeline/getsAvailableDates';
        public static downloadOutlooktimeline = 'outlookTimeline/download';
    };

    // API Urls for MBP users page
    public static MBPUser = class {
        public static ApiGetPoolUsersList = 'user/getPoolUsers';
        public static ApiPostUploadContract = 'poolAccountCustomerDocs/uploadContract';
        public static ApiGetDocsList = 'poolAccountCustomerDocs/list';
        public static ApiGetDocsDownload = 'poolAccountCustomerDocs/downloadFile';
        public static ApiGetDeleteDoc = 'poolAccountCustomerDocs/deleteDocs';
        public static ApiPostBuyerCreate = 'buyer/create';
        public static ApiGetBuyerDetail = 'buyer/getBuyerDetail';
        public static ApiGetDeleteBuyer = 'buyer/deleteBuyer';
        public static ApiGetBushelDetail = 'poolAccountCustomerQtyHistory/getBushelDetail';
        public static ApiGetBuyerSumQty = 'poolAccountCustomerQtyHistory/getBuyerSumQTy';
        public static ApiCreditDebitBushel = 'poolAccountCustomerQtyHistory/saveBushelInfo';
        public static ApiCreditDebitBushelDelete = 'poolAccountCustomerQtyHistory/deleteBushelInfo';
        public static ApiGetUserPoolAccountQty = 'user/getUserPoolAccountQty';
        public static ApiGetCheckEhedgerMBP = 'user/checkEhedgerMBP';
        public static ApiGetMBPUnsoldQty = 'user/getMBPUnsoldQty';
    };
    // API Urls for published strategies page
    public static PublishedStrategies = class {
        public static getPublishedStrategyDetails = 'analyticsInputs/getPublishedStrategyDetails';
        public static getEntitiesCounties = 'analyticsInputs/getEntitiesCounties';
        public static getExpiryDates = 'analyticsStrategy/getExpireDates';
        public static getStrategyDetailsByID = 'analyticsInputs/getStrategyDetailsByID';
        public static getMoveClearStrategies = 'whatif/moveClearStratagies';
        public static getPdf = 'analyticsInputs/getPDF';
        public static getAccountSummary = 'dashboard/getAccountSumm';
    };

    /** CSRF  */
    public static CSRF = class {
        public static getCsrfCode = 'oauth/getCSRFCode';
    };

    /** Resource-page module api relative url  */
    public static resourcePage = class {
        public static getData = 'resourcePage/getData';
        public static update = 'resourcePage/update';
    };

    /** Docusign */
    public static Docusing = class {
        public static getDocusignAgreement = 'esign/getDocusignAgreement';
        public static checkAgreementStatus = 'esign/checkAgreementStatus';
    };

    /** Crop Add in portfolio module api relative url  */
    public static PortfolioMultipleCrop = class {
        public static CropTypeList = 'cropType/getAllCropType';
        public static getActiveCropYears = 'user/getActiveCropYears';
        public static CreateMultipleCrop = 'fieldCrop/addPortfolioSignupCrop';
        public static FetchMultiCropDetails = 'fieldCrop/getMultiCropById';
        public static DeleteCrop = 'fieldCrop/deleteMultiCrop';
        public static getEntityByCropId = 'fieldCrop/getEntityByCropId';
    };

    // API Urls for Hedges outlook page
    public static NewHedgesSnapshot = class {
        public static HedgesSnapshotSettingDetail = 'forecastMatrix/fetchHedgesSettingData';
    };

    /** Flex Rent */
    public static FlexRent = class {
        public static AccumulationDetails = 'fieldCrop/fetchAccumulation';
        public static UpdateAccumulation = 'fieldCrop/updateAccumulation';
    };

    /* Pen Account Summary */
    public static PenAccountSummary = class {
        public static AccountSummaryList = '/penAccSummary/getAccountSumm';
        public static Report = '/penAccSummary/loadReport';
    }
        
    /** Alert - Cron/Fo Price report */

    public static Alert = class{
        public static AlertGetCronReport = 'AlertAndNotifications/getCronReport';
        public static AlertFOPriceReport = 'AlertAndNotifications/getFOPriceReport';
    };

    /** Benchmarking */
    public static BenchMarking = class {
        public static BenmarkingDetails = '/benchmarking/benchmarkingDetails';
        public static ExpectedList = '/benchmarking/expectedList';
        public static UnprizedList= '/benchmarking/unprizedList';
        public static TotalGrainList= '/benchmarking/totalGrainList';
        public static CropInsuaranceList= '/benchmarking/cropInsuaranceList';
        public static OtherRevenueList= '/benchmarking/otherRevenueList';
        public static GrossRevenueList= '/benchmarking/grossRevenueList';
        public static NetGainLossList= '/benchmarking/netGainLossList';
        public static PrizedList= '/benchmarking/prizedList';
        public static FutureOptionList= '/benchmarking/futureOptionList';
        public static MainDetail= '/benchmarking/mainDetail';
        public static BenchmarkReport = '/benchmarking/loadBenchmarkReport';
        public static BenchmarkDownloadReport = '/benchmarking/downloadReport';
        
    }
    public static HedgeReportsAudio = class{
        public static GetAllVideo = 'video/getCustomerVideos';
        public static CheckVideoLinkExist = 'video/checkVideoLinkExists';
    }
    /** Field module api relative url  */
    /** Field CLU module api relative url  */
    public static FieldClu = class {
        public static ApiFieldList = 'field/getFieldListFrmSolr';
        public static getOrgLocation = 'user/getOrgLocation';
        public static getKMLFile = 'field/getKML';
        public static findKML = 'field/findKML';
        public static getCropTypeByFieldID = 'fieldCrop/getFieldCropByFieldId';
        public static getFieldAndCropListForCLU = 'field/getFieldCropDtls';

        /** Add Clu Field */
        public static ApiGetEntityList = 'field/getEntityList';
        public static ApiGetCountyList = 'field/getCountyList';
        public static ApiGetFieldDetails = 'field/getFieldById';
        public static ApiGetCLUFieldList = 'field/cluFieldList';
        public static ApiGetFieldList = 'field/getFieldsByYearAndOrgId';
        public static ApiUploadShapeData = 'shape/handleShapeDataWithBase64';
        public static ApiGetFieldSave = 'field/createField';
        public static ApiGetFieldEdit = 'field/Update';
        
    };
    
    /* BackTesting */
    public static BackTesting = class {
        public static fetchPoolListBT = '/backtestingPool/fetchPoolListBT';
        public static changePoolStatus = '/backtestingPool/changePoolStatus';
        public static deletePool = '/backtestingPool/deletePool';
        public static getPoolChart = '/backtestingPool/getPoolChart';        
    };
    
}