/** This pipe will used to convert the input value to decimal format. Display with comma. In also handle the -ve Value. */
import { Pipe, PipeTransform } from '@angular/core';
import { Util } from '../common/util';

@Pipe({
    name: 'decimalFormat'
})
export class DecimalFormatPipe implements PipeTransform {
    transform(value: string, floatFixedDigit: number, removeTrailingZero: boolean = true): string {
        // If in input floatFixedDigit is blank then consider default value to be 2.
        const toFixed = Util.notBlank(floatFixedDigit) ? floatFixedDigit : 2;

        // If in input value is blank then return the 0.00
        if (Util.notBlank(value)) {
            // If in input value is -ve then display the () bracket around the formatted value
            if (parseFloat(value) >= 0) {
                return Util.numberWithCommas(Util.removeTrailingZeros(value, toFixed, removeTrailingZero));
            } else {
                return '(' + Util.numberWithCommas(Util.removeTrailingZeros(Math.abs(Number(value)), toFixed, removeTrailingZero)) + ')';
            }
        } else {
            return '0.00';
        }
    }
}
