/** GlobalErrorHandler is used to handle the error globally. */
import { Injectable, ErrorHandler, Injector } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    /** constructor */
    constructor(private injector: Injector) {}

    /** handleError function will be used to handle the error globally which is not handle at code level */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    handleError(error: any): void {
        console.error(error);
        // Log the error into sentry
        // const eventId = Sentry.captureException(error.originalError || error);
        const eventId = 0;
        // Console the error with sentry eventId
        this.injector.get(Router);
    }
}
