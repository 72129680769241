/** WebStorageService is used to store the data client side either in local storage or session storage */
import { Injectable } from '@angular/core';

// This key will be prefix of any storage item...
const KEY_PREFIX = 'agyield';

/** Created Common WebStorageService class that will be base class for the Local Storage as well as Session Storage */
export class WebStorageService {
    /** constructor - Storage is injected  */
    constructor(private storage: Storage) {}

    /** get function will be used to retrieve the data from the client side storage for the given key*/
    get(key: string): any {
        return WebStorageUtility.get(this.storage, key);
    }

    /** set function will be used to store the data in client side storage for the given key*/
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    set(key: string, value: any): void {
        WebStorageUtility.set(this.storage, key, value);
    }

    /** remove function will be used to remove the data from client side storage for the given key*/
    remove(key: string): void {
        WebStorageUtility.remove(this.storage, key);
    }

    /** clear function will be used to remove all the data from client side storage */
    clear(): void {
        this.storage.clear();
    }
}

/** LocalStorageService that will extends the WebStorageService */
@Injectable({
    providedIn: 'root'
})
export class LocalStorageService extends WebStorageService {
    constructor() {
        super(localStorage);
    }
}

/** SessionStorageService that will extends the WebStorageService */
@Injectable()
export class SessionStorageService extends WebStorageService {
    constructor() {
        super(sessionStorage);
    }
}

/** WebStorageUtility static utility class to store/retrieve/remove the data from client side storage */
export class WebStorageUtility {
    /** generateStorageKey function will be used to append the prefix key for given key*/
    static generateStorageKey(key: string): string {
        return `${KEY_PREFIX}_${key}`;
    }

    /** get function will be used to retrieve the data from client side given storage(LocalStorage/SessionStorage) */
    static get(storage: Storage, key: string): any {
        const storageKey = WebStorageUtility.generateStorageKey(key);

        const value: any = storage.getItem(storageKey);

        return WebStorageUtility.getGettable(value);
    }

    /** set function will be used to set the data in client side given storage(LocalStorage/SessionStorage) */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static set(storage: Storage, key: string, value: any): void {
        const storageKey = WebStorageUtility.generateStorageKey(key);

        storage.setItem(storageKey, WebStorageUtility.getSettable(value));
    }

    /** remove function will be used to remove the data for the given key from client side given storage(LocalStorage/SessionStorage) */
    static remove(storage: Storage, key: string): void {
        const storageKey = WebStorageUtility.generateStorageKey(key);

        storage.removeItem(storageKey);
    }

    /** getSettable function will be used to convert the JSON object to string while storing to any client side storage */
    private static getSettable(value: any): string {
        return typeof value === 'string' ? value : JSON.stringify(value);
    }

    /** getGettable function will be used to convert the string object to JSON while retrieving data from client side storage */
    private static getGettable(value: string): any {
        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    }
}
