/** BaseMessage model has common properties for alert as well as confirmation dialog*/
export class BaseMessage {
    public static Type = class {
        public static warning = 'warning';
        public static error = 'error';
        public static info = 'info';
        public static success = 'success';
    };

    public static Title = class {
        public static oops = 'Oops!!';
        public static great = 'Great';
        public static warning = 'Warning';
        public static confirm = 'Are you sure?';
    };

    type: any;
    title: any;
    text: any;
    html: any;
}

/** AlertMessage model is used to display text/html message*/
export class AlertMessage extends BaseMessage {
    confirmButtonText: any = 'OK';
    callBack: any;
}

/** ConfirmationMessage model is used to display confirmation */
export class ConfirmationMessage extends BaseMessage {
    isShowCancelButton = true;
    
    confirmButtonText: any = '<i class="fa fa-thumbs-up"></i> Yes';
    cancelButtonText: any = '<i class="fa fa-thumbs-down"></i> No';
    finishRenewUpgradeButtonText: any = '<i class="fa fa-thumbs-up"></i> Finish';
    okCallBack: any;
    cancelCallBack: any;
    allowOutsideClick?: boolean;
}