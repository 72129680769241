/* Api List for bearer authorization */

import { ayObject } from '../interface/shared-interface';

export const apiList: ayObject = {
    user: ['verifyOtp', 'createPortfolioUser'],
    portfolioCustQuesAns: ['savePortfolioCustQuesAns', 'getCustSelectedAns'],
    fieldCrop: ['addPortfolioSignupCrop', 'getMultiCropById'],
    portfolioMaster: [
        'getActivePortfolioDetail',
        'getBasePrice',
        'getGuarantee',
        'savePortfolioCrop',
        'getLevels',
        'getAcreChartDate',
        'saveUserPortfolio'
    ],
    discountCode: ['validateDiscountCode', 'validateDealerCode'],
    portfolioPayment: ['create'],
    esign: ['getDocusignAgreement', 'checkAgreementStatus']
};
