/** Enum to be used in components to be defined here so can be shared across application*/
enum isMoMasterChecked {
    false = '0',
    true = '1'
}
enum isMoAdmin {
    false = '0',
    true = '1'
}
enum windowStorage {
    change_customer = 'change_customer',
    ag_change_customer = 'agyield_change_customer'
}
enum customerType {
    MO = 'MO',
    ADV = 'ADV'
}
enum closedLastTab {
    one = '1',
    two = '2'
}
enum trueFlaseStr {
    true = 'true',
    false = 'false'
}
enum roleCode {
    ADM = 'ADM',
    STF = 'STF',
    SLS = 'SLS',
    OAD = 'OAD',
    OUS = 'OUS'
}
enum orgType {
    TNT = 'TNT'
}
enum landUnitName {
    Acres = 'Acres',
    acres = 'acres',
    Hectares = 'Hectares'
}
enum landUnitNameSingular {
    Acre = 'Acre',
    Hectare = 'Hectare',
    acre = 'acre',
    yield = 'yield',
    head = 'head'
}
enum landUnitNameShort {
    Acre = '/ac'
}

enum userType {
    SO = 'SO'
}
enum year {
    Y2018 = '2018'
}
enum responseStatus {
    destroyed = 'destroyed',
    deactivated = 'deactivated'
}
enum mbpWise {
    mbp = 'mbp',
    Participant = 'Participant'
}

enum fileType {
    pdf = 'pdf',
    excel = 'excel',
    xls = 'xls'
}

enum transType {
    MANAGEDPOOL = 'MANAGEDPOOL',
    MBP = 'MBP',
    HTA = 'HTA',
    MINMAX = 'MINMAX',
    MinSlashMax = 'Min/Max',
    MinAndMax = 'MIN/MAX',
    OPTIONS = 'OPTIONS',
    FUTURES = 'FUTURES',
    BASIS = 'BASIS',
    Basis = 'Basis',
    BASISContract = 'Open Basis Contract',
    BASISCONTRACT = 'OPEN BASIS CONTRACTS',
    ACCUMULATOR = 'ACCUMULATOR',
    Accumulator = 'Accumulator',
    accumulator = 'accumulator',
    cash = 'cash',
    CASH = 'CASH',
    customContract = 'customContract',
    CUSTOMCONTRACT = 'CUSTOM CONTRACT',
    CSO = 'CSO',
    Spread = 'Spread',
    percentage = 'percentage',
    PERCENTAGE = 'PERCENTAGE',
    Percentage = 'Percentage',
    Insurance = 'Insurance',
    SPREAD = 'SPREAD',
    firmOffer = 'firmOffer',
    FIRMOFFER = 'FIRM OFFER',
    WHATIFFIRMOFFER = 'FIRMOFFER'
}

enum templateTitle {
    Add = 'Add',
    Edit = 'Edit'
}
enum globalCurrency {
    dollar = '$',
    dollarCan = '$CAN'
}
enum targetPaqge {
    Login = 'Login',
    Signup = 'Signup',
    Renew = 'Renew',
    SignupAdmin = 'SignupAdmin',
    FreeTrial = 'FreeTrial'
}
enum dateFormat {
    MMddyyyy = 'MM/dd/yyyy',
    MMddyy = 'MM/dd/yy'
}

enum yesNoStr {
    Yes = 'Yes',
    No = 'No'
}

enum uom {
    Bushels = 'Bushels',
    Pound = 'Pound'
}

enum shortUom {
    Bushels = 'bu',
    Pound = 'lb'
}

enum zipMaskStr {
    fiveDigit = '00000',
    sixDigit = 'S0S0S0'
}

enum countryStr {
    CA = 'CA',
    Canada = 'Canada'
}

enum discountCodeType {
    Dealer = 'Dealer',
    Coupon = 'Coupon'
}

enum revenueType {
    Ci = 'ci',
    Sp = 'sp',
    Ub = 'ub',
    Cs = 'cs',
    Cu = 'cu',
    Fo = 'fo',
    Cop = 'cop',
    Or = 'or',
    Actual = 'actual',
    Whatif = 'with_whatif',
    Both = 'both'
}
export class AppEnum {
    public static isMoMasterChecked = isMoMasterChecked;
    public static isMoAdmin = isMoAdmin;
    public static windowStorage = windowStorage;
    public static customerType = customerType;
    public static closedLastTab = closedLastTab;
    public static roleCode = roleCode;
    public static orgType = orgType;
    public static landUnitName = landUnitName;
    public static landUnitNameSingular = landUnitNameSingular;
    public static userType = userType;
    public static responseStatus = responseStatus;
    public static landUnitNameShort = landUnitNameShort;
    public static year = year;
    public static mbpWise = mbpWise;
    public static fileType = fileType;
    public static transType = transType;
    public static templateTitle = templateTitle;
    public static globalCurrency = globalCurrency;
    public static targetPaqge = targetPaqge;
    public static dateFormat = dateFormat;
    public static trueFlaseStr = trueFlaseStr;
    public static yesNoStr = yesNoStr;
    public static uom = uom;
    public static revenueType = revenueType;
    public static shortUom = shortUom;
    public static zipMaskStr = zipMaskStr;
    public static countryStr = countryStr;
    public static discountCodeType = discountCodeType;
}