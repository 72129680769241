<div class="sidebar" (mouseleave)="closeAllMenus()">
  <ul>
    <li *ngFor="let menuItem of navItems">
      <a *ngIf="menuItem?.children" (mouseenter)="toggleSubMenu(menuItem)">
        <i [attr.id]="menuItem.name" class="icon" [class]="menuItem.icon"></i>
        <div  *ngIf="menuItem?.expanded" class="menu-name active">
          {{ menuItem?.name }}          
        </div>
      </a>
      <a *ngIf="!menuItem?.children"(mouseenter)="toggleSubMenu(menuItem)" [routerLink]="menuItem?.url">
        <i [attr.id]="menuItem.name" class="icon" [class]="menuItem.icon"></i>
        <div *ngIf="menuItem?.expanded" class="menu-name">
          <ul>
            <li  [routerLink]="menuItem?.url">{{ menuItem?.name }}</li>
          </ul>
        </div>
      </a>
      <div *ngIf="menuItem?.expanded" class="submenu">
        <ul>
          <li *ngFor="let subMenuItem of menuItem?.children">
            <a *ngIf="!subMenuItem?.children" [routerLink]="subMenuItem?.url"  [ngClass]="{ 'disabled': subMenuItem?.attributes?.disabled }">
              <i [class]="subMenuItem?.icon" class="mr-2"></i> {{subMenuItem?.name}}
            </a>
            <a *ngIf="subMenuItem?.children"  (click)="subMenuItem.expanded = !subMenuItem.expanded"   [ngClass]="{ 'disabled': subMenuItem?.attributes?.disabled }" >
              <i [class]="subMenuItem?.icon" class="mr-2"></i> {{subMenuItem?.name}} <i class="fa fa-chevron-down float-end white-icon faicon"></i>
            </a>
            <div *ngIf="subMenuItem?.children?.length > 0 && subMenuItem?.expanded" class="supermenu">
              <ul>
                <li *ngFor="let child of subMenuItem?.children">
                    <a *ngIf = "!(child?.url =='/resources/CashGrainQuotes' && global.getVal('elevator') == 0)" [ngClass]="{ 'disabled': child?.attributes?.disabled }" [routerLink]="child?.url">{{child.name}}</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
