/** AppRoutingModule is used to define all the parent module route(s) and make sure that it is lazy loaded*/
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { CommonModule } from '@angular/common';
import { DefaultLayoutComponent } from './containers';

// Import Component
import { P404Component } from './modules/error/404.component';
import { P500Component } from './modules/error/500.component';
import { AuthGuard } from './modules/shared/guard/auth.guard';

// Routes
export const routes: Routes = [
    {
        path: '404',
        component: P404Component,
        data: {
            title: 'Page 404'
        }
    },
    {
        path: '500',
        component: P500Component,
        data: {
            title: 'Page 500'
        }
    },
    {
        path: 'portfolio-registration/:id',
        loadChildren: () =>
            import('./modules/portfolio-registration/portfolio-registration.module').then((m) => m.PortfolioRegistrationModule)
    },
    {
        path: 'signup-renewal',
        loadChildren: () => import('./modules/signup-renewal/signup-renewal.module').then((m) => m.SignupRenewalModule)
    },
    {
        path: 'renew-plan/:id',
        loadChildren: () => import('./modules/renew-plan/renew-plan.module').then((m) => m.RenewPlanModule)
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./modules/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule)
    },

    {
        path: 'set-password/:invitationId/:userId',
        loadChildren: () => import('./modules/set-password/set-password.module').then((m) => m.SetPasswordModule)
    },
    {
        path: 'so-approve/:invKey',
        loadChildren: () => import('./modules/so-approve/so-approve.module').then((m) => m.SoApproveModule)
    },
    {
        path: 'signup-renewal/:renewalCode/:userId',
        loadChildren: () => import('./modules/signup-renewal/signup-renewal.module').then((m) => m.SignupRenewalModule)
    },
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        data: {
            title: 'Home'
        },
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule)
            },
            {
                path: 'pen-dashboard',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/pen-dashboard/pen-dashboard.module').then((m) => m.PenDashboardModule)
            },
            {
                path: 'discount-code',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/discount-code/discount-code.module').then((m) => m.DiscountCodeModule)
            },
            {
                path: 'portfolio-mbp',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/portfolio-mbp/portfolio-mbp.module').then((m) => m.PortfolioMbpModule)
            },
            {
                path: 'add-portfolio-mbp/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/add-portfolio-mbp/add-portfolio-mbp.module').then((m) => m.AddPortfolioMbpModule)
            },
            {
                path: 'approve-portfolio-mbp',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/approve-portfolio-mbp/approve-portfolio-mbp.module').then((m) => m.ApprovePortfolioMbpModule)
            },
            {
                path: 'mbpUser',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/mbp-user/mbp-user.module').then((m) => m.MbpUserModule)
            },
            {
                path: 'mbp-user',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/mbp-users/mbp-users.module').then((m) => m.MbpUsersModule)
            },
            {
                path: 'field',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/field/field.module').then((m) => m.FieldModule)
            },
            {
                path: 'field-clu',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/field-clu/field-clu.module').then((m) => m.FieldCluModule)
            },
            {
                path: 'portfolio-questionnaire',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/portfolio-questionnaire/portfolio-questionnaire.module').then((m) => m.PortfolioQuestionnaireModule)
            },
            {
                path: 'pen-insurance/entry/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/pen-insurance-entry/pen-insurance-entry.module').then((m) => m.PenInsuranceEntryModule)
            },
            {
                path: 'insurance/entry/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/insurance-entry/insurance-entry.module').then((m) => m.InsuranceEntryModule)
            },            
            {
                path: 'officecode',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/office-code/office-code.module').then((m) => m.OfficeCodeModule)
            },
            {
                path: 'my-account',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/my-account/my-account.module').then((m) => m.MyAccountModule)
            },
            /* {
                path: 'customers',
                //canActivate: [AuthGuard],
                //loadChildren: () => import('./modules/customers/customers.module').then((m) => m.CustomersModule)
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/customers/customer-list/customer-list.module').then((m) => m.CustomersModule)
            }, */
            {
                path: 'simulator-users',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/simulator-user/simulator-user.module').then((m) => m.SimulatorUserModule)
            },
            {
                path: 'customers',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/customers/customer.module').then((m) => m.CustomerModule)
            },
            {
                path: 'advisors',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/advisors/advisor.module').then((m) => m.AdvisorModule)
            },
            {
                path: 'crop-default',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/crop-default/crop-default.module').then((m) => m.CropDefaultModule)
            },
            {
                path: 'crop-default-new',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/crop-default-new/crop-default-new.module').then((m) => m.CropDefaultNewModule)
            },
            {
                path: 'benchmarking',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/benchamrking/benchamrking.module').then((m) => m.BenchamrkingModule)
            },
            {
                path: 'expense',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/expense/expense.module').then((m) => m.ExpenseModule)
            },
            {
                path: 'pen-expense',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/pen-expense/expense.module').then((m) => m.ExpenseModule)
            },
            {
                path: 'agyield-mbp',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/agyield-mbp/agyield-mbp.module').then((m) => m.AgyieldMbpModule)
            },
            {
                path: 'activity-history',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/activity-history/activity-history-list.module').then((m) => m.ActivityHistoryListModule)
            },
            {
                path: 'mbp',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/mbp/mbp.module').then((m) => m.MbpModule)
            },
            {
                path: 'indemnity-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/indenmity-entry-transaction/indenmity-entry-transaction.module').then(
                        (m) => m.IndenmityEntryTransactionModule
                    )
            },
            {
                path: 'pen-indemnity-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/pen-indenmity-entry-transaction/pen-indenmity-entry-transaction.module').then(
                        (m) => m.PenIndenmityEntryTransactionModule
                    )
            },
            {
                path: 'crops',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/crops/crops.module').then((m) => m.CropsModule)
            },
            {
                path: 'pens',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/pen/pen.module').then((m) => m.PenModule)
            },
            {
                path: 'entities',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/entity-list/entity-list.module').then((m) => m.EntityListModule)
            },
            {
                path: 'transactions',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/transaction-list/transaction-list.module').then((m) => m.TransactionListModule)
            },
            {
                path: 'pen-transactions',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/pen-transaction-list/pen-transaction-list.module').then((m) => m.PenTransactionListModule)
            },
            {
                path: 'account-summary',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/account-summary/account-summary.module').then((m) => m.AccountSummaryModule)
            },
            {
                path: 'pen-account-summary',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/pen-account-summary/pen-account-summary.module').then((m) => m.PenAccountSummaryModule)
            },
            {
                path: 'global-strategy',
                loadChildren: () => import('./modules/global-strategy/global-strategy.module').then((m) => m.GlobalStrategyModule)
            },
            {
                path: 'transaction-roll-over/:id',
                loadChildren: () =>
                    import('./modules/transaction-roll-over/transaction-roll-over.module').then((m) => m.TransactionRollOverModule)
            },
            {
                path: 'profitability-outlook',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/profitability-outlook/profitability-outlook.module').then((m) => m.ProfitabilityOutlookModule)
            },
            {
                path: 'performance-chart',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/performance-chart/performance-chart.module').then((m) => m.PerformanceChartModule)
            },
            {
                path: 'athena',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/athena/athena.module').then((m) => m.AthenaModule)
            },
            {
                path: 'user/entry/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/user-entry/user-entry.module').then((m) => m.UserEntryModule)
            },
            
            {
                path: 'user-list',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/user-list/user-list.module').then((m) => m.UserListModule)
            },
            {
                path: 'organization',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/organization/organization.module').then((m) => m.OrganizationModule)
            },
            {
                path: 'hedges-snapshot',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/hedges-snapshot/hedges-snapshot.module').then((m) => m.HedgesSnapshotModule)
            },
            {
                path: 'croptype',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/crop-type/crop-type.module').then((m) => m.CropTypeModule)
            },
            {
                path: 'trading-account/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/trading-account/trading-account-list.module').then((m) => m.TradingAccountListModule)
            },
            {
                path: 'insurance',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/insurance-list/insurance-list.module').then((m) => m.InsuranceListModule)
            },
            {
                path: 'pen-insurance',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/pen-insurance-list/pen-insurance-list.module').then((m) => m.PenInsuranceListModule)
            },

            {
                path: 'pen-transaction-future/entry/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/pen-transaction-future-entry/pen-transaction-future-entry.module').then((m) => m.PenTransactionFutureEntryModule)
            },

            {
                path: 'transaction-future/entry/:id',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/transaction-future-entry/transaction-future-entry.module').then((m) => m.TransactionFutureEntryModule)
            },
            {
                path: 'transaction-option/entry/:id',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/transaction-option-entry/transaction-option-entry.module').then((m) => m.TransactionOptionEntryModule)
            },
            {
                path: 'pen-transaction-option/entry/:id',
                canActivate: [AuthGuard],
                loadChildren: () =>
                import('./modules/pen-transaction-option-entry/pen-transaction-option-entry.module').then((m) => m.PenTransactionOptionEntryModule)
            },
            {
                path: 'accumulator-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/accumulator-transaction/accumulator-transaction.module').then((m) => m.AccumulatorTransactionModule)
            },
            {
                path: 'minmax-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/minmax-transaction/minmax-transaction.module').then((m) => m.MinmaxTransactionModule)
            },
            {
                path: 'other-revenue-transaction/:id',
                loadChildren: () =>
                    import('./modules/other-revenue-transaction/other-revenue-transaction.module').then(
                        (m) => m.OtherRevenueTransactionModule
                    )
            },
            {
                path: 'htambp-transaction/:type/:id',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/hta-mbp-transaction/hta-mbp-transaction.module').then((m) => m.HtaMbpTransactionModule)
            },
            {
                path: 'pen-htambp-transaction/:type/:id',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/pen-hta-mbp-transaction/pen-hta-mbp-transaction.module').then((m) => m.PenHtaMbpTransactionModule)
            },
            {
                path: 'pen-basis-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/pen-basis-transaction/pen-basis-transaction.module').then((m) => m.PenBasisTransactionModule)
            },
            {
                path: 'basis-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/basis-transaction/basis-transaction.module').then((m) => m.BasisTransactionModule)
            },            
            {
                path: 'cash-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/cash-transaction/cash-transaction.module').then((m) => m.CashTransactionModule)
            },
            {
                path: 'pen-cash-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/pen-cash-transaction/pen-cash-transaction.module').then((m) => m.PenCashTransactionModule)
            },
            {
                path: 'pen-minmax-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/pen-minmax-transaction/pen-minmax-transaction.module').then((m) => m.PenMinmaxTransactionModule)
            },
            {
                path: 'pen-other-revenue-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/pen-other-revenue-transaction/pen-other-revenue-transaction.module')
                .then((m) => m.PenOtherRevenueTransactionModule)
            },

            
            {
                path: 'custom-contract-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/custom-contract-transaction/custom-contract-transaction.module').then(
                        (m) => m.CustomContractTransactionModule
                    )
            },
            {
                path: 'pen-firm-offer-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/pen-firm-offer-transaction/pen-firm-offer-transaction.module').then((m) => m.PenFirmOfferTransactionModule)
            },
            {
                path: 'firm-offer-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/firm-offer-transaction/firm-offer-transaction.module').then((m) => m.FirmOfferTransactionModule)
            },
            {
                path: 'percentage-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/percentage-transaction/percentage-transaction.module').then((m) => m.PercentageTransactionModule)
            },
            {
                path: 'pen-percentage-transaction/:id',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/pen-percentage-transaction/pen-percentage-transaction.module').then((m) => m.PenPercentageTransactionModule)
            },
            {
                path: 'fieldExpense',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/expense-list/expense-list.module').then((m) => m.ExpenseListModule)
            },
            {
                path: 'news',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/news-list/news-list.module').then((m) => m.NewsListModule)
            },
            {
                path: 'resource/cropprophet',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/resources/crop-prophet/crop-prophet.module').then((m) => m.CropProphetModule)
            },
            {
                path: 'resources/HedgeReportsAudio/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/resources/video-audio-commentary/video-audio-commentary.module').then((m) => m.VideoAudioCommentaryModule)
            },
            {
                path: 'resources/HedgeReportsAudio',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/resources/video-audio-commentary/video-audio-commentary.module').then((m) => m.VideoAudioCommentaryModule)
            },
            {
                path: 'resources/HedgeRecommendations/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/resources/hedge-recommendation/hedge-recommendation.module').then((m) => m.HedgeRecommendationsModule)
            },
            {
                path: 'resources/HedgeRecommendations',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/resources/hedge-recommendation/hedge-recommendation.module').then((m) => m.HedgeRecommendationsModule)
            },
            {
                path: 'resource/news',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/news-list/news-list.module').then((m) => m.NewsListModule)
            },
            {
                path: 'add-news/:id',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/add-news/add-news.module').then((m) => m.AddNewsModule)
            },
            {
                path: 'logout',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/logout/logout.module').then((m) => m.LogoutModule)
            },
            {
                path: 'backtesting',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/backtesting/backtesting.module').then((m) => m.BacktestingModule)
            },
            {
                path: 'resources/:resource',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/resources/resources.module').then((m) => m.ResourcesModule)
            },
            {
                path: 'resources/:resource/:query',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/resources/resources.module').then((m) => m.ResourcesModule)
            },
            /*{
                path: 'simulator-games',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/simulator-games/simulator-games.module').then((m) => m.SimulatorGamesModule)
            },*/
            {
                path: 'simulator-games',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/simulator-game/simulator-game.module').then((m) => m.SimulatorGameModule)
            },
            {
                path: 'alert',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/alert/alert.module').then((m) => m.AlertModule)
            },
            {
                path: 'mbp-user',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/mbp-users/mbp-users.module').then((m) => m.MbpUsersModule)
            },
            {
                path: 'published-strategies',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./modules/published-strategies/published-strategies.module').then((m) => m.PublishedStrategiesModule)
            },
            {
                path: 'commentary',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/resource-pages/resource-pages.module').then((m) => m.ResourcePagesModule)
            },
            {
                path: 'resources',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/resources/resources.module').then((m) => m.ResourcesModule)
            },
            {
                path: 'modal-unpriced-committed-grain-list',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/benchamrking/modal-unpriced-committed-grain-list/modal-unpriced-committed-grain-list.module').then((m) => m.ModalUnpricedCommittedGainListModule)
            },
            {
                path: 'modal-future-options-list',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/benchamrking/modal-future-options-list/modal-future-options-list.module').then((m) => m.ModalFutureOptionsListModule)
            },
            {
                path: 'modal-priced-grain-list',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/benchamrking/modal-priced-grain-list/modal-priced-grain-list.module').then((m) => m.ModalPricedGrainListModule)
            },
            {
                path: 'benchmark-report',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/benchamrking/benchmark-report/benchmark-report.module').then((m) => m.BenchmarkReportModule)
            },
            {
                path: 'field-clu-entry',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/field-clu/field-clu-entry/field-clu-entry.module').then((m) => m.FieldCluEntryModule)
            },
        ]
    },
    { path: '**', component: P404Component }
];

@NgModule({
    imports: [RouterModule.forRoot(routes /*, { relativeLinkResolution: 'legacy' } */), CommonModule],
    exports: [RouterModule]
})
export class AppRoutingModule {}