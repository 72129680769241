import { Component } from '@angular/core';

// Import globals for singleton use. To use share variables across application.
import { Globals } from '../shared/common/globals';

@Component({
    selector: 'ay-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
    public isVisible = false;
    public globals;

    constructor() {
        // Get singleton class globals.
        this.globals = Globals.getInstance();

        // Set current class instance to loader key in globals. So can be used across application.
        this.globals.setVal('loader', this);
    }

    // Function show loader
    show(): void {
        this.isVisible = true;
        this.globals.setVal('isLoaderVisible', true);
    }

    // Function to hide loader
    hide(): void {
        this.isVisible = false;
        this.globals.setVal('isLoaderVisible', false);
    }
}
