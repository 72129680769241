import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Location } from '@angular/common';
import { LocalStorageService } from '../services/webstorage.service';
import { CommonService } from '../services/common.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private storageService: LocalStorageService,
        private commonService: CommonService,
        private location: Location
    ) {}

    canActivate(): boolean {
        let result = false;
        const userId = this.storageService.get('userId');
        const authToken = this.storageService.get('oauth_token');
        if (window.location.hash === '#/portfolio-registration') {
            result = true;
        }
        if (authToken && userId) {
            result = true;
        } else {
            this.router.navigateByUrl('/auth/login');
            result = false;
        }

        return result;
    }
}
