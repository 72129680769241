/* This class is a Injectable class which will act as singleton instance across application*/
import { Injectable } from '@angular/core';
import { ayObject } from '../interface/shared-interface';

// Declare class as injectable
@Injectable()
export class Globals {
    private static instance: Globals = Globals.getInstance();
    public app: ayObject;

    // Constructor will set
    private constructor() {
        this.app = {};
    }

    // Return the instance of the service as singleton
    public static getInstance(): Globals {
        // set new instance if current instance is null
        if (Globals.instance === null || Globals.instance === undefined) {
            Globals.instance = new Globals();
        }

        return Globals.instance;
    }

    /* Function to be used to set value in global singleton object.*/
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public setVal(key: string, val: any): void {
        this.app[key] = val;
    }

    /* Function to be used to get value from global singleton object based on key.*/
    public getVal(key: string): any {
        // if value set for the key then return the value else null
        if (this.app.hasOwnProperty(key)) {
            return this.app[key];
        } else {
            return null;
        }
    }

    /* Function to get all the global values set in the globals.*/
    public getAll(): ayObject {
        return this.app;
    }
}
